export const cellsKeys = {
  type: 'type',
  count: 'count',
  complexity: 'complexity',
  cost: 'cost',
  discount: 'discount'
}

export const tableCells = {
  [cellsKeys.type]: 'Вид работ',
  [cellsKeys.count]: 'К-во расчетов',
  [cellsKeys.complexity]: 'Сложность',
  [cellsKeys.cost]: 'Стоимость',
  [cellsKeys.discount]: 'Скидка'
}

export const itemsKeys = {
  isEnabled: 'isEnabled',
  count: 'count',
  complexity: 'complexity',
  cost: 'cost',
  discount: 'discount',
  isAdditional: 'isAdditional',
  formula: 'formula',
  initialCost: 'initialCost',
  shortcut: 'shortcut',
  dist: 'dist',
  basePrice: 'basePrice',
  sbeList: 'sbeList'
}

export const calculationDifficulties = [1, 2, 3]

export const parameters = {
  analyze: 'analyze',
  wedgeCount: 'wedgeCount',
  flatRoofPMCount: 'flatRoofPMCount',
  flatRoofRMCount: 'flatRoofRMCount',
  windPM: 'windPM',
  windRM: 'windRM',
  thermalCalculationSP23: 'thermalCalculationSP23',
  thermalCalculationSP50: 'thermalCalculationSP50',
  steamAccumulation: 'steamAccumulation',
  fence: 'fence',
  windZones: 'windZones',
  plateLayout: 'plateLayout'
}

export const parametersNames = {
  [parameters.analyze]: 'Анализ чертежей',
  [parameters.wedgeCount]: 'Расчет количества клиновидной теплоизоляции',
  [parameters.flatRoofPMCount]: 'Расчет количества материалов Плоской кровли ПМ',
  [parameters.flatRoofRMCount]: 'Расчет количества материалов Плоской кровли РМ',
  [parameters.windPM]: 'Ветровой расчет ПМ',
  [parameters.windRM]: 'Ветровой расчет РМ',
  [parameters.thermalCalculationSP23]: 'Теплотехнический расчет методика СП 23-02-2003',
  [parameters.thermalCalculationSP50]: 'Теплотехнический расчет по методике СП 50',
  [parameters.steamAccumulation]: 'Расчет паронакопления СП 50-13330.2012',
  [parameters.fence]: 'Расчет ограждения',
  [parameters.windZones]: 'Создание схемы ветровых зон',
  [parameters.plateLayout]: 'Оформление раскладки гидроизоляции на кровле'
}

const [firstComplexity] = calculationDifficulties

export const calculationPriceStepItems = {
  [parameters.analyze]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.shortcut]: 'A',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.wedgeCount]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.shortcut]: 'Кл',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.flatRoofPMCount]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.shortcut]: 'Мпм',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.flatRoofRMCount]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.shortcut]: 'Мрм',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.windPM]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.count]: 1,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.shortcut]: 'Впм',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.windRM]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.count]: 1,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.shortcut]: 'Врм',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.thermalCalculationSP23]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.count]: 1,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.isAdditional]: true,
    [itemsKeys.shortcut]: 'Т23',
    [itemsKeys.dist]: {},
    [itemsKeys.sbeList]: {
      kv: {
        isEnabled: false,
        name: 'КВ',
        key: 'kv',
        index: 0
      },
      xps: {
        isEnabled: false,
        name: 'XPS',
        key: 'xps',
        index: 1
      },
      pir: {
        isEnabled: false,
        name: 'PIR',
        key: 'pir',
        index: 2
      }
    },
    [itemsKeys.basePrice]: 0
  },
  [parameters.thermalCalculationSP50]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.isAdditional]: true,
    [itemsKeys.shortcut]: 'Т50',
    [itemsKeys.dist]: {},
    [itemsKeys.sbeList]: {
      kv: {
        isEnabled: false,
        name: 'КВ',
        key: 'kv',
        index: 0
      },
      xps: {
        isEnabled: false,
        name: 'XPS',
        key: 'xps',
        index: 1
      },
      pir: {
        isEnabled: false,
        name: 'PIR',
        key: 'pir',
        index: 2
      }
    },
    [itemsKeys.basePrice]: 0
  },
  [parameters.steamAccumulation]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.count]: 1,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.isAdditional]: true,
    [itemsKeys.shortcut]: 'П',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.fence]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.complexity]: firstComplexity,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.isAdditional]: true,
    [itemsKeys.shortcut]: 'О',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: 0
  },
  [parameters.windZones]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.isAdditional]: true,
    [itemsKeys.shortcut]: 'СВЗ',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: {},
    sectors: []
  },
  [parameters.plateLayout]: {
    [itemsKeys.isEnabled]: false,
    [itemsKeys.cost]: 0,
    [itemsKeys.discount]: 100,
    [itemsKeys.isAdditional]: true,
    [itemsKeys.shortcut]: 'РГИ',
    [itemsKeys.dist]: {},
    [itemsKeys.basePrice]: {},
    sectors: []
  }
}

export const getInitialStateForPriceStep = () =>
  Object.fromEntries(
    Object.entries(calculationPriceStepItems).map(([key, val]) => {
      const { formula, initialCost, ...rest } = val
      return [key, { ...rest }]
    })
  )
