<template>
  <div class="header__content-user">
    <user-controls v-if="isLoggedIn" @updateAuthStatus="updateAuthStatus" />
    <login-btn v-else @click="showLoginModal">
      <img src="@/assets/img/user/login.svg" alt="login" />
    </login-btn>
    <modal :is-modal-open="isShowLoginModal" @close="closeLoginModal">
      <template #body>
        <user-login @logIn="logIn" />
      </template>
    </modal>
  </div>
</template>

<script>
// import { atata } from '@/api/moderator'
import { mapState, mapMutations } from 'vuex'
import LoginBtn from '@/components/smart/HeaderUser/LoginBtn'
import Modal from '@/components/elements/Modals/Modal'
import UserLogin from '@/components/elements/Modals/UserLogin'
import UserControls from '@/components/smart/HeaderUser/UserControls'
import { moderatorLocalStorageHelper } from '@/utils/moderator'
export default {
  name: 'HeaderUser',
  data: () => ({
    isShowLoginModal: false
  }),
  components: { UserControls, UserLogin, Modal, LoginBtn },
  methods: {
    ...mapMutations('moderator', {
      UPDATE_AUTH_STATUS: 'UPDATE_AUTH_STATUS'
    }),
    updateAuthStatus(data, status) {
      const { csrf_token: csrfToken, logout_token: logoutToken, current_user: currentUser } = data
      const { uid } = currentUser
      this.UPDATE_AUTH_STATUS({
        status,
        uid
      })
      moderatorLocalStorageHelper.setTokens(csrfToken, logoutToken)
    },
    closeLoginModal() {
      this.isShowLoginModal = false
    },
    showLoginModal() {
      this.isShowLoginModal = true
    },
    logIn(data) {
      this.closeLoginModal()
      this.updateAuthStatus(data.data, true)
    }
  },
  computed: {
    ...mapState('moderator', {
      isLoggedIn: state => state.isAuth
    })
  }
}
</script>

<style scoped lang="sass">
.header__content
  &-user
    display: flex
</style>
