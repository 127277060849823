import { cloneDeep, isEmpty } from 'lodash'
import { applicationDataKeys, generateCipher } from '@/utils/deepSettings'
import { calculationPriceStepItems, itemsKeys, parameters } from '@/utils/calculationPriceStep'
import { sumElements } from '@/utils/customFunctions'
import { getDeepSettingsData, setUserSettings } from '@/api/moderator'
import {
  initialDeepSettingsState,
  applicationData,
  objectData,
  calculationPrice,
  calculationAccuracy,
  customerClientData,
  files
} from '@/utils/InitialDeepSettingsState'
import { sumArr } from '@/utils/drawing'
import Vue from 'vue'
import { getInsulationTypeForSector } from '@/utils'

export const moderatorModule = {
  namespaced: true,
  state: {
    defaultSector: {
      uid: null,
      name: '',
      square: 1,
      insulationType: null,
      complexity: 1,
      cost: 0
    },
    isAuth: false,
    userId: null,
    status: null,
    currentHash: null,
    ...cloneDeep(initialDeepSettingsState),
    customerTypes: [],
    calculationTypes: [],
    buildingStages: [],
    clientTypes: [],
    insulationTypes: [],
    calculationStatus: [],
    accuracyStepData: [],
    regions: [],
    dateRange: [],
    activeCells: [],
    isFormFalid: false
  },
  mutations: {
    UPDATE_VALID_FORM: (state, formFalid) => {
      state.isFormFalid = formFalid
    },
    RESTORE_DEFAULT_DEEP_SETTINGS: state => {
      const clonedDeepSettings = cloneDeep(initialDeepSettingsState)
      Object.entries(clonedDeepSettings).forEach(([key, val]) => {
        state[key] = val
      })
    },
    SET_CURRENT_CALCULATION_HASH: (state, hash) => {
      state.currentHash = hash
    },
    UPDATE_AUTH_STATUS: (state, { status, uid }) => {
      state.isAuth = status
      state.userId = uid
    },
    UPDATE_PARAM: (state, { key, param, val }) => {
      state[key][param] = val
    },
    UPDATE_CALCULATION_PRICE_PARAM: (state, { key, param, val, cost }) => {
      state[calculationPrice][key][param] = val
      if (typeof cost === 'object') {
        state[calculationPrice][key][itemsKeys.cost] = cost.price
      }
      state[calculationPrice][key][itemsKeys.basePrice] = cost.basePrice
      state[calculationPrice][key][itemsKeys.dist] = { ...cost.dist }
    },
    RESTORE_CALCULATION_PRICE_PARAM_TO_DEFAULT: (state, key) => {
      const targetParam = state[calculationPrice][key]
      const initialParamState = cloneDeep(calculationPriceStepItems[key])
      const { sectors, ...rest } = initialParamState
      const newParamState = {
        ...rest,
        isEnabled: false
      }
      state[calculationPrice][key] = Object.assign(targetParam, newParamState)
    },
    UPDATE_SPECIAL_CALCULATION_PRICE_PARAM: (state, { key, val }) => {
      state[calculationPrice][key] = { ...val }
    },
    SET_DEEP_SETTINGS_DATA: (state, payload) => {
      const { insulationTypes } = payload
      const [firstFoundInsulationType] = insulationTypes
      state.defaultSector.insulationType = firstFoundInsulationType.id
      Object.entries(payload).forEach(entry => {
        const [key, val] = entry
        const keyToSetData = key === calculationAccuracy ? 'accuracyStepData' : key
        state[keyToSetData] = Object.freeze(val)
      })
      const accuracyStepData = payload[calculationAccuracy]
      accuracyStepData.forEach(item => {
        Vue.set(state[calculationAccuracy], item.id, null)
      })
    },
    SET_INITIAL_SECTORS_STATE_TO_PRICE_STEP: (state, sectors) => {
      state[calculationPrice].windZones.sectors = cloneDeep(sectors)
      state[calculationPrice].plateLayout.sectors = cloneDeep(sectors)
    },
    SET_COST_FOR_ALL_PRICE_STEP_ITEMS: (state, items) => {
      const specialParameters = [parameters.windZones, parameters.plateLayout]
      Object.entries(items).forEach(([key, val]) => {
        const isSpecialParameter = specialParameters.includes(key)
        const target = state[calculationPrice][key]
        if (isSpecialParameter) {
          target.sectors = target.sectors.map(sector => {
            return {
              ...sector,
              cost: val.price[sector.uid]
            }
          })
          target.dist = { ...val.dist }
          target.basePrice = { ...val.basePrice }
          target.cost = sumArr(Object.values(val.price))
        } else {
          target.cost = val.price
          target.dist = { ...val.dist }
          target.basePrice = val.basePrice
        }
      })
    },
    DELETE_SECTOR_FROM_PRICE: (state, { parameter, uid }) => {
      delete state[calculationPrice][parameter].dist[uid]
      delete state[calculationPrice][parameter].basePrice[uid]
      // prettier-ignore
      state[calculationPrice][parameter].sectors =
        state[calculationPrice][parameter].sectors
          .filter(sector => sector.uid !== uid)
    },
    ADD_SECTOR_TO_PRICE: (state, { parameter, cost, uid, basePrice, dist }) => {
      const targetSectors = state[calculationPrice][parameter].sectors
      const newSector = { ...state.defaultSector }
      state[calculationPrice][parameter].dist = {
        ...state[calculationPrice][parameter].dist,
        [uid]: dist
      }
      state[calculationPrice][parameter].basePrice = {
        ...state[calculationPrice][parameter].basePrice,
        [uid]: basePrice
      }
      newSector.uid = uid
      newSector.name = `Участок ${targetSectors.length + 1}`
      newSector.cost = cost
      targetSectors.push(newSector)
    },
    UPDATE_SECTOR_PARAM_IN_PRICE_BY_KEY: (
      state,
      { isNeedUpdateCost, key, param, uid, val, cost }
    ) => {
      const targetSector = state[calculationPrice][key].sectors.find(sector => sector.uid === uid)
      state[calculationPrice][key].dist[uid] = { ...cost.dist[uid] }
      state[calculationPrice][key].basePrice[uid] = cost.basePrice[uid]
      targetSector[param] = val
      if (isNeedUpdateCost) {
        targetSector.cost = cost.price[uid]
      }
    },
    SET_SPECIAL_CALCULATION_PARAM_TOTAL_COST: (state, { key, totalCost }) => {
      state[calculationPrice][key].cost = totalCost
    },
    UPDATE_SBE: (state, { sbeName, sbeKey, val }) => {
      state[calculationPrice][sbeName].sbeList[sbeKey].isEnabled = val
    },
    UPDATE_SBE_DIST: (state, { sbeName, cost }) => {
      state[calculationPrice][sbeName].dist = { ...cost.dist }
    },
    SET_SAVED_DEEP_SETTINGS: (state, { deepSettings }) => {
      const {
        applicationData,
        calculationAccuracy,
        calculationPrice,
        customerClientData,
        objectData,
        files,
        status
      } = deepSettings
      const { startDate, endDate } = applicationData
      state.applicationData = {
        ...applicationData,
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      }
      state.calculationPrice = calculationPrice
      state.customerClientData = customerClientData
      state.objectData = objectData
      state.status = status
      state.files = files
      if (!isEmpty(calculationAccuracy)) {
        state.calculationAccuracy = calculationAccuracy
      }
    },
    SET_FIlES_FROM_EXCEL_IMPORT: (state, files) => {
      state.files = {
        ...files
      }
    },
    SET_DATA_FROM_EXCEL_IMPORT: (state, data) => {
      Object.entries(data).forEach(([key, val]) => {
        if (key === applicationData && val.hasOwnProperty('startDate')) {
          val.startDate = new Date(val.startDate)
        }
        state[key] = {
          ...state[key],
          ...val
        }
      })
    },
    UPDATE_DATA_RANGE: (state, { payload }) => {
      state.dateRange = payload
    },
    UPDATE_ACTIVE_CELLS: (state, { payload }) => {
      state.activeCells = payload
    },
    UPDATE_SBE_PARAM_IN_PRICE_BY_KEY: (state, { itemKey, param, val, cost }) => {
      const sbe = state[calculationPrice][itemKey]
      sbe[param] = val
      sbe.cost = cost.price
      sbe.dist = { ...cost.dist }
    }
  },
  getters: {
    getActiveCells: state => {
      return state.activeCells
    },
    getDateRange: state => {
      return state.dateRange
    },
    getIsFormFalid: state => {
      return state.isFormFalid
    },
    getDeepSettingByKey: state => key => {
      return state[key]
    },
    getPriceItemByParameter: state => parameter => {
      return state.calculationPrice[parameter]
    },
    getAllDeepSettings: state => {
      return {
        [applicationData]: state[applicationData],
        [objectData]: state[objectData],
        [customerClientData]: state[customerClientData],
        [calculationPrice]: state[calculationPrice],
        [calculationAccuracy]: state[calculationAccuracy],
        [files]: state[files],
        status: state.status
      }
    }
  },
  actions: {
    setDataRange: ({ commit }, payload) => {
      commit('UPDATE_DATA_RANGE', {
        payload
      })
    },
    setActiveCells: ({ commit }, payload) => {
      commit('UPDATE_ACTIVE_CELLS', {
        payload
      })
    },
    updateApplicationDataParam: ({ state, commit }, { param, val, isNeedGenerateCipher }) => {
      commit('UPDATE_PARAM', {
        key: applicationData,
        param,
        val
      })
      if (isNeedGenerateCipher) {
        const { userId, calculationTypes } = state
        const {
          calculationNumber,
          endDate,
          calculationType,
          variantNumber,
          variantsCount,
          cloneIndex
        } = state[applicationData]
        commit('UPDATE_PARAM', {
          key: applicationData,
          param: [applicationDataKeys.cipher],
          val: generateCipher(
            userId,
            calculationNumber,
            endDate,
            state[calculationPrice],
            calculationType,
            variantNumber,
            variantsCount,
            cloneIndex,
            calculationTypes
          )
        })
      }
    },
    updateObjectDataParam: ({ commit }, { param, val }) => {
      commit('UPDATE_PARAM', {
        key: objectData,
        param,
        val
      })
    },
    updateCustomerClientDataParam: ({ commit }, { param, val }) => {
      commit('UPDATE_PARAM', {
        key: customerClientData,
        param,
        val
      })
    },
    updateCalculationAccuracyParam: ({ commit }, { param, val }) => {
      commit('UPDATE_PARAM', {
        key: calculationAccuracy,
        param,
        val
      })
    },
    updateSpecialCalculationParam: ({ state, commit }, { key, val }) => {
      const totalCost = sumElements(val.sectors, 'cost')
      commit('UPDATE_SPECIAL_CALCULATION_PRICE_PARAM', { key, val })
      commit('SET_SPECIAL_CALCULATION_PARAM_TOTAL_COST', { key, totalCost })
      const { userId } = state
      const { calculationNumber, endDate } = state[applicationData]
      commit('UPDATE_PARAM', {
        key: applicationData,
        param: [applicationDataKeys.cipher],
        val: generateCipher(userId, calculationNumber, endDate, state[calculationPrice])
      })
    },
    commonUpdateSpecialCalculationParam: (
      { state, commit },
      { key, param, val, cost, isNeedUpdateCipher }
    ) => {
      commit('UPDATE_CALCULATION_PRICE_PARAM', { key, param, val, cost })
      if (isNeedUpdateCipher) {
        const { userId } = state
        const { calculationNumber, endDate } = state[applicationData]
        commit('UPDATE_PARAM', {
          key: applicationData,
          param: [applicationDataKeys.cipher],
          val: generateCipher(userId, calculationNumber, endDate, state[calculationPrice])
        })
      }
    },
    updateSbeParamInPriceByKey: ({ commit }, { itemKey, param, val, cost }) => {
      commit('UPDATE_SBE_PARAM_IN_PRICE_BY_KEY', {
        itemKey,
        param,
        val,
        cost
      })
    },
    updateSectorParamInPriceByKey: (
      { state, commit },
      { isNeedUpdateCost, key, param, uid, val, cost }
    ) => {
      commit('UPDATE_SECTOR_PARAM_IN_PRICE_BY_KEY', {
        isNeedUpdateCost,
        key,
        param,
        uid,
        val,
        cost
      })
      if (isNeedUpdateCost) {
        const totalCost = sumElements(state[calculationPrice][key].sectors, 'cost')
        commit('SET_SPECIAL_CALCULATION_PARAM_TOTAL_COST', { key, totalCost })
      }
    },
    deleteSectorFromPrice: ({ state, commit }, { parameter, uid }) => {
      commit('DELETE_SECTOR_FROM_PRICE', { parameter, uid })
      const totalCost = sumElements(state[calculationPrice][parameter].sectors, 'cost')
      commit('SET_SPECIAL_CALCULATION_PARAM_TOTAL_COST', { key: parameter, totalCost })
    },
    addSectorToPrice: ({ state, commit }, { parameter, cost, uid, basePrice, dist }) => {
      commit('ADD_SECTOR_TO_PRICE', { parameter, cost, uid, basePrice, dist })
      const totalCost = sumElements(state[calculationPrice][parameter].sectors, 'cost')
      commit('SET_SPECIAL_CALCULATION_PARAM_TOTAL_COST', { key: parameter, totalCost })
    },
    restoreCalculationPriceParamToDefault: ({ state, commit }, key) => {
      commit('RESTORE_CALCULATION_PRICE_PARAM_TO_DEFAULT', key)
      const { userId } = state
      const { calculationNumber, endDate } = state[applicationData]
      commit('UPDATE_PARAM', {
        key: applicationData,
        param: [applicationDataKeys.cipher],
        val: generateCipher(userId, calculationNumber, endDate, state[calculationPrice])
      })
    },
    updateUserSettings: ({ state }) => {
      setUserSettings(state.activeCells)
    },
    getDeepSettingsData: ({ state, commit }, lang) => {
      if (isEmpty(state.customerTypes)) {
        getDeepSettingsData(lang).then(response => {
          commit('SET_DEEP_SETTINGS_DATA', response.data)
        })
      }
      commit('RESTORE_DEFAULT_DEEP_SETTINGS')
      if (state.currentHash) {
        commit('SET_CURRENT_CALCULATION_HASH', null)
      }
    },
    setInitialSectorsToPriceStep: ({ state, commit }, originalSectors) => {
      const isNeedToSetInitialSectors =
        isEmpty(state[calculationPrice].windZones.sectors) &&
        isEmpty(state[calculationPrice].plateLayout.sectors)
      if (isNeedToSetInitialSectors) {
        const { cost, complexity } = state.defaultSector
        const initialSectorsState = cloneDeep(originalSectors).map(sector => {
          const { name, square, uid } = sector
          return {
            uid,
            name,
            square,
            insulationType: getInsulationTypeForSector(sector.layers, state.insulationTypes),
            complexity,
            cost
          }
        })
        commit('SET_INITIAL_SECTORS_STATE_TO_PRICE_STEP', initialSectorsState)
      }
    }
  }
}

export default moderatorModule
