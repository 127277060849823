const tokens = {
  csrfToken: 'csrfToken',
  logoutToken: 'logoutToken'
}

export const moderatorLocalStorageHelper = {
  setTokens: (csrfToken, logoutToken) => {
    localStorage.setItem(tokens.csrfToken, csrfToken)
    localStorage.setItem(tokens.logoutToken, logoutToken)
  },
  clearTokens: () => {
    localStorage.removeItem(tokens.csrfToken)
    localStorage.removeItem(tokens.logoutToken)
  },
  getTokens: () => {
    return {
      [tokens.csrfToken]: localStorage.getItem(tokens.csrfToken),
      [tokens.logoutToken]: localStorage.getItem(tokens.logoutToken)
    }
  }
}
