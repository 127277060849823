<template>
  <div class="footer__content-user">
    <app-button
      class="footer__content-btn footer__content-btn"
      color="grey"
      v-if="isLoggedIn"
      @updateAuthStatus="updateAuthStatus"
      @click="goToDashboard"
      ><img src="@/assets/img/user/login.svg" alt="login" />Кабинет</app-button
    >
    <app-button class="footer__content-btn" color="grey" v-else @click="showLoginModal">
      <img src="@/assets/img/user/login.svg" alt="login" />
      Вход
    </app-button>

    <modal :is-modal-open="isShowLoginModal" @close="closeLoginModal">
      <template #body>
        <user-login @logIn="logIn" />
      </template>
    </modal>
  </div>
</template>

<script>
// import { atata } from '@/api/moderator'
import { mapState, mapMutations } from 'vuex'
import AppButton from '@/components/elements/AppButton'
import UserLogin from '@/components/elements/Modals/UserLogin'
import Modal from '@/components/elements/Modals/Modal'

import { moderatorLocalStorageHelper } from '@/utils/moderator'
export default {
  name: 'FooterUser',
  data: () => ({
    isShowLoginModal: false
  }),
  components: { Modal, AppButton, UserLogin },
  methods: {
    ...mapMutations('moderator', {
      UPDATE_AUTH_STATUS: 'UPDATE_AUTH_STATUS'
    }),
    updateAuthStatus(data, status) {
      const { csrf_token: csrfToken, logout_token: logoutToken, current_user: currentUser } = data
      const { uid } = currentUser
      this.UPDATE_AUTH_STATUS({
        status,
        uid
      })
      moderatorLocalStorageHelper.setTokens(csrfToken, logoutToken)
    },
    closeLoginModal() {
      this.isShowLoginModal = false
    },
    showLoginModal() {
      this.isShowLoginModal = true
    },
    logIn(data) {
      this.closeLoginModal()
      this.updateAuthStatus(data.data, true)
    },
    goToDashboard() {
      if (!this.isInDashboardNow) {
        this.$router.push(`/${this.$i18n.locale}/dashboard`)
      }
    }
  },
  computed: {
    ...mapState('moderator', {
      isLoggedIn: state => state.isAuth
    })
  }
}
</script>

<style scoped lang="sass">
.footer__content
  &-user
    display: flex
  &-btn
    padding: rem(5) rem(15) rem(5) rem(5)
    img
      filter: invert(39%) sepia(2%) saturate(99%) hue-rotate(358deg) brightness(88%) contrast(91%)
      height: rem(30)
      width: rem(30)
      transition: 0.5s
@mdeia(any-hover: hover)
.footer__content
  &-btn
    &:hover img
      filter: brightness(0) invert(1)
</style>
