export const setVh = () => {
  let vh = document.documentElement.clientHeight
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  ;(function () {
    const throttle = (type, name, obj) => {
      obj = obj || window
      let running = false
      const func = function () {
        if (running) {
          return
        }
        running = true
        requestAnimationFrame(() => {
          obj.dispatchEvent(new CustomEvent(name))
          running = false
        })
      }
      obj.addEventListener(type, func)
    }

    /* init - you can init any event */
    throttle('resize', 'optimizedResize')
  })()
  window.addEventListener('optimizedResize', () => {
    vh = document.documentElement.clientHeight
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })
}
