<template>
  <div class="user-controls">
    <tippy interactive placement="bottom-end" theme="light">
      <template #trigger>
        <div class="user-controls__userpic">
          <img src="@/assets/img/user/userpic.svg" alt="userpic" />
        </div>
      </template>
      <user-controls-drop-down />
    </tippy>
  </div>
</template>

<script>
import UserControlsDropDown from '@/components/smart/HeaderUser/UserControlsDropDown'
export default {
  name: 'UserControls',
  components: { UserControlsDropDown },
  methods: {
    handler() {
      this.$emit('updateAuthStatus', false)
    }
  }
}
</script>

<style scoped lang="sass">
.user-controls
  height: rem(40)
  width: rem(40)
  display: flex
  align-items: center
  justify-content: center
  flex: 0 0 rem(40)
  line-height: 0
</style>
