<template>
  <div id="app">
    <Header :is-full-width="isFullWidthHeader" v-if="isShowHeader" />
    <Nav ref="Nav" />
    <router-view />
    <notifications group="copyToClip" position="top right">
      <template slot="body" slot-scope="props">
        <div class="vue-notification-template vue-notification custom">
          <img :src="props.item.text" alt="i" />
          <div class="notification-title">
            {{ props.item.title }}
          </div>
        </div>
      </template>
    </notifications>
    <notifications group="layerError" position="top right" />
    <notifications group="apiNotifications" position="top right" />
    <Footer v-if="isShowFooter" />
  </div>
</template>

<script>
import { setVh } from '@/utils/setVh'
import Nav from '@/components/smart/Nav'
import Footer from '@/components/dump/Footer'
import Header from '@/components/dump/Header'
import { getUserId } from '@/api/moderator'
import { mapMutations, mapActions } from 'vuex'
import { moderatorLocalStorageHelper } from '@/utils/moderator'

const routesWithoutFooter = ['Dashboard', 'DeepSettings', 'MissingPage', '404']
const routesWithFullWidthHeader = ['Dashboard', 'DeepSettings']
const routesWithHeader = [
  ...routesWithFullWidthHeader,
  'Result',
  'Details',
  'DetailsJunction',
  'Save'
]

export default {
  name: 'app',
  provide() {
    return {
      slideToLast: this.slideToLast
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.meta.title')
    }
  },
  components: {
    Header,
    Nav,
    Footer
  },
  methods: {
    slideToLast() {
      this.$refs.Nav.goToLastSlide()
    },
    ...mapMutations('moderator', {
      UPDATE_AUTH_STATUS: 'UPDATE_AUTH_STATUS'
    }),
    ...mapActions('moderator', {
      setActiveCells: 'setActiveCells'
    })
  },
  mounted() {
    // localStorage.setItem('lang', this.$i18n.locale);
    // if (localStorage.getItem('lang')) {
    //   console.log(localStorage.getItem('lang'));
    //   this.$i18n.locale = localStorage.getItem('lang');
    //   // this.$router.push(`/${localStorage.getItem('lang')}/`)
    // } else {
    //   console.log(this.$route.params.lang);
    //   localStorage.setItem('lang', this.$route.params.lang);
    // }
    setVh()
  },
  created() {
    try {
      getUserId().then(response => {
        const uid = response.data.id
        const settings = response.data.settings
        if (settings) {
          this.setActiveCells(settings)
        }
        if (uid) {
          this.UPDATE_AUTH_STATUS({
            status: true,
            uid
          })
        } else {
          moderatorLocalStorageHelper.clearTokens()
        }
      })
    } catch (e) {}
  },
  computed: {
    isShowFooter() {
      return !routesWithoutFooter.includes(this.$route.name)
    },
    isShowHeader() {
      return routesWithHeader.includes(this.$route.name)
    },
    isFullWidthHeader() {
      return routesWithFullWidthHeader.includes(this.$route.name)
    }
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')

#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

body
  font-family: 'Montserrat', sans-serif
  margin: 0
  background: $ec
  overflow-x: hidden
  &.bg--white
    background: $white

  #app
    text-align: left

  &.modal-open
    overflow-y: hidden

  .container
    max-width: rem(960)
    +media((width: (0: 100%, 960: calc(100% - 32px), 992: rem(960))))
    margin: auto
    position: relative

html
  scroll-behavior: smooth
  -moz-scroll-behavior: smooth

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type='number']
  -moz-appearance: textfield
</style>
