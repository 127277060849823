// import { cloneDeep } from 'lodash'

export const findJunctionOriginalName = (index, sectorJunctions, allJunctions) => {
  const currentJunction = sectorJunctions[index]
  const isJunctionCreatedByUser = currentJunction.isCustom && !currentJunction.id
  const junction = getJunction(currentJunction)
  if (isJunctionCreatedByUser) {
    return currentJunction.customJunctionName.toLowerCase()
  } else {
    const originalJunction = getJunctionById(junction, allJunctions)
    return originalJunction ? originalJunction.toLowerCase() : ''
  }
}

const getJunction = currentJunction => {
  const { id, groupId } = currentJunction
  const isSingle = !Object.prototype.hasOwnProperty.call(currentJunction, 'groupId')
  return isSingle ? { id, isSingle } : { id, groupId, isSingle }
}
const getJunctionById = (junction, allJunctions) => {
  const { id, groupId, isSingle } = junction
  return isSingle
    ? allJunctions.find(j => j.id === id).name // allJunctions.find(j => j.id === id)
    : allJunctions.find(g => g.id === groupId).items.find(j => j.id === id).title // allJunctions.find(g => g.id === groupId).groupName
}

export const filterFasteners = (baseTypeId, placementType, fasteners) => {
  // prettier-ignore
  return baseTypeId === null
    ? fasteners
    : fasteners.filter(fastener => {
      return (
        fastener.baseTypes.includes(baseTypeId) && fastener.placementTypes.includes(placementType)
      )
    })
}

export const isValidNumber = (number, canBeZero = false) =>
  !isNaN(parseFloat(number)) && canBeZero ? number >= 0 : number > 0

export const scrollAndHighLightErrorField = target => {
  const offsetTop = target.getBoundingClientRect().top + document.documentElement.scrollTop
  window.scrollTo(0, offsetTop - 100)
  target.classList.add('v-error')
  setTimeout(() => {
    target.classList.remove('v-error')
  }, 1500)
}

export const prepareAlbums = (currentAlbums, allAlbums) => {
  return [...currentAlbums].map(item => {
    return allAlbums.find(album => album.id === item)
  })
  // return newAlbums.map(album => {
  //   return (album = cloneDeep(albums.find(a => a.id === album)))
  // })
}

export const getRandomId = () => Math.random().toString(36).substr(2, 9)

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const sliceStr = str => (str ? (str.length >= 46 ? `${str.slice(0, 46)}...` : str) : '')

export const pages = {
  save: 'save',
  result: 'result',
  details: 'details',
  wedge: 'wedge',
  deepSettings: 'deepSettings'
}

export const getInsulationTypeForSector = (layers, moderatorInsulationTypes) => {
  const [firstInsulationType] = moderatorInsulationTypes
  const firstFoundedParentLayer = layers.find(layer => layer.insulation)
  if (firstFoundedParentLayer) {
    const firsFoundedChildLayer = firstFoundedParentLayer.items.find(item => item.insulation)
    return firsFoundedChildLayer
      ? findModeratorInsulationType(firsFoundedChildLayer, moderatorInsulationTypes).id
      : firstInsulationType.id
  } else {
    return firstInsulationType.id
  }
}

const findModeratorInsulationType = (childLayer, moderatorInsulationTypes) => {
  const [firstInsulationType] = moderatorInsulationTypes
  return (
    moderatorInsulationTypes.find(item => item.id === childLayer.material.category) ||
    firstInsulationType
  )
}
