import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { cloneDeep, isEmpty } from 'lodash'
import { normalizeResponseUnits } from '@/translations'
import { saveAs } from 'file-saver'
import moderatorModule from './modules/moderator/index'
import {
  getFastenerInfo,
  isLayerHasFastener,
  calculateGlueSquare,
  isTargetLayerInWedgeExist
} from '@/utils/customFunctions'
import { filterFasteners, getRandomId, isSafari, pages } from '@/utils'
import {
  getContent,
  fetchSlopeMaterials,
  deleteDrawing,
  getResults,
  saveResult,
  getSavedProject,
  getPdf,
  getXls,
  checkTNToken
} from '@/api'
import { uuid } from 'vue-uuid'
import { sendMetrics } from '@/api/metrics'
import { getDeepSettingsData } from '@/api/moderator'
Vue.use(Vuex)

class Sectors {
  constructor(sectors, defaultBaseId) {
    this.sectors = sectors
    this.defaultBaseid = defaultBaseId
  }
  get addFastenersInfo() {
    this.sectors.map(p => {
      for (let i = 0; i < p.layers.length; i += 1) {
        for (let n = 0; n < p.layers[i].items.length; n += 1) {
          const item = p.layers[i].items[n]
          if (isLayerHasFastener(item)) {
            item.fasteners.computedInfo = getFastenerInfo(
              i,
              n,
              cloneDeep(p.layers),
              this.defaultBaseid
            )
          }
        }
      }
    })
    return this.sectors
  }
  get prepareWedge() {
    // this.addFastenersInfo
    this.sectors.forEach(p => {
      if (!isTargetLayerInWedgeExist(p.layers, 'wedge')) {
        delete p.wedge
      }
      if (
        isTargetLayerInWedgeExist(p.layers, 'wedge') &&
        !isTargetLayerInWedgeExist(p.layers, 'glue')
      ) {
        p.wedge.glue = 0
      } // @TODO проверка не верная
    })
    return this.addFastenersInfo // this.sectors
  }
  get prepareSectorsToApi() {
    // this.prepareWedge
    return this.prepareWedge // this.sectors
  }
}

class Item {
  constructor(item) {
    this.item = item
  }
  get setComputedFastenersRate() {
    const rates = []
    if (this.item.material.fastenersRate) {
      rates.push(this.item.material.fastenersRate)
    }
    if (this.item.computedFastenersRate) {
      rates.push(this.item.computedFastenersRate)
    } else {
      rates.push(0)
      // this.item.computedFastenersRate = Math.max(...rates);
      // Vue.set(this.item, 'computedFastenersRate', 0);
    }
    this.item.computedFastenersRate = Math.max(...rates)
    return this.item
  }
}

class Layers {
  constructor(layers) {
    this.layers = layers
  }
  get setComputedFastenersRate() {
    for (let i = 0; i < this.layers.length; i += 1) {
      for (let n = 0; n < this.layers[i].items.length; n += 1) {
        const item = this.layers[i].items[n]
        const rates = []
        if (Object.prototype.hasOwnProperty.call(item, 'fasteners')) {
          rates.push(item.fasteners.rate)
        }
        if (Object.prototype.hasOwnProperty.call(item.material, 'fastenersRate')) {
          rates.push(item.material.fastenersRate)
        }
        if (Object.prototype.hasOwnProperty.call(item, 'computedFastenersRate')) {
          rates.push(item.computedFastenersRate)
          item.computedFastenersRate = Math.max(...rates)
        }
      }
    }
    return this.layers
  }
}

export default new Vuex.Store({
  modules: {
    moderator: moderatorModule
  },
  state: {
    sessionToken: null,
    stepNumbers: null,
    TNTokenStatus: false,
    TNToken: null,
    hash: null,
    albums: [],
    user: {},
    result: {},
    isFromLink: false,
    junctionCategories: [],
    junctions: [],
    materialCategories: [],
    systemsCategories: [],
    systems: [],
    fasteners: [],
    slopeProducts: [],
    slopeCategories: [],
    slopeFasteners: [],
    slopeFastenersCategories: [],
    layersList: [],
    junctionsLayersList: [],
    sectors: [
      {
        name: 'Участок 1',
        system: '',
        systemType: 'defaultSystem',
        isCustom: false,
        customSystemName: 'Пользовательская система',
        square: 0,
        junctions: [],
        layers: [],
        uid: 'dm8wgwmb4'
      }
    ],
    baseTypes: [],
    defaultSector: {
      name: 'Участок 1',
      square: 0,
      junctions: [],
      system: '',
      layers: [],
      systemType: 'defaultSystem',
      customSystemName: 'Пользовательская система',
      isCustom: false,
      uid: null
    },
    defaultCustomSector: {
      name: 'Участок 1',
      square: 0,
      junctions: [],
      system: '',
      layers: [],
      systemType: 'customSystem',
      isCustom: true,
      customSystemName: 'Пользовательская система',
      uid: null
    },
    defaultJunction: {
      uid: 'l8pncd39a',
      id: null,
      drawingUrl: null,
      count: 0,
      type: 'chiseled',
      layers: [],
      junctionType: 'defaultJunction',
      isCustom: false,
      customJunctionName: 'Пользовательский узел примыкания'
    },
    defaultCustomJunction: {
      id: null,
      drawingUrl: null,
      count: 0,
      type: 'chiseled',
      layers: [],
      junctionType: 'customJunction',
      isCustom: true,
      customJunctionName: 'Пользовательский узел примыкания',
      uid: 'opqz6o756'
    },
    defaultFastener: {
      // isAttach: false,
      rate: 0,
      selected: 9005
    },
    defaultMountLayer: {
      name: 'Крепежный элемент',
      type: 'mount', // [layer, mount]
      mountRate: 0, // может не быть
      mark: '',
      height: 0,
      basementType: null, // это ид из всех возможных типов найти самому
      selected: 0
    },
    defaultJunctionLayer: {
      materialRate: 0, // может не быть
      mark: '',
      type: 'layer'
    },
    pages: {
      [pages.result]: {
        isLoading: true
      },
      [pages.wedge]: {
        isLoading: false
      },
      [pages.details]: {
        isLoading: false
      },
      [pages.save]: {
        isLoading: false
      },
      [pages.deepSettings]: {
        isLoading: true
      }
    },
    defaultBaseId: 0,
    defaultSystemSlope: {
      slopes: {
        mainSlope: {
          id: null,
          plates: []
        },
        contrSlope: {
          id: null,
          plates: []
        }
      },
      fasteners: [],
      glue: 0,
      square: 0
    }
  },
  mutations: {
    // //////////////////////////////////////STATE/////////////////////////////////////
    UPDATE_TN_TOKEN_STATUS: (state, TNTokenStatus) => {
      state.TNTokenStatus = TNTokenStatus
    },

    UPDATE_TN_TOKEN: (state, token) => {
      state.TNToken = token
    },
    UPDATE_SESSION_TOKEN: (state, sessionToken) => {
      state.sessionToken = sessionToken
    },
    UPDATE_STEP_NUMBER: (state, stepNumber) => {
      state.stepNumbers = stepNumber
    },
    UPDATE_PAGE_LOADING_STATUS: (state, { page, status }) => {
      state.pages[pages[page]].isLoading = status
    },
    UPDATE_USER_INFO: (state, payload) => {
      state.user = cloneDeep(payload)
    },
    UPDATE_USER_HASH: (state, payload) => {
      state.user.hash = payload
    },
    UPDATE_HASH: (state, payload) => {
      state.hash = payload
    },
    UPDATE_CONTENT: (state, payload) => {
      Vue.set(state, 'systems', Object.freeze(cloneDeep(payload.systems)))
      Vue.set(state, 'systemsCategories', Object.freeze(cloneDeep(payload.systemsCategories)))
      Vue.set(state, 'baseTypes', Object.freeze(cloneDeep(payload.baseTypes)))
      Vue.set(state, 'fasteners', Object.freeze(cloneDeep(payload.mounting)))
      Vue.set(state, 'junctions', Object.freeze(cloneDeep(payload.junctions)))
      Vue.set(state, 'junctionsCategories', Object.freeze(cloneDeep(payload.junctionsCategories)))
      Vue.set(state.defaultCustomSector, 'layers', cloneDeep(payload.defaultSystem.layers))
      Vue.set(state, 'junctionsLayersList', Object.freeze(cloneDeep(payload.junctionsLayers)))
      Vue.set(state, 'layersList', Object.freeze(cloneDeep(payload.systemsLayers)))
      Vue.set(state, 'materialCategories', Object.freeze(cloneDeep(payload.materialCategories)))
      //  Vue.set(state, 'albums', cloneDeep(Object.keys(payload.albums).map((key) => payload.albums[key])));
      Vue.set(state, 'albums', Object.freeze(cloneDeep(payload.albums)))
      state.defaultBaseId = Object.freeze(payload.defaultBaseId)
      state.defaultMountLayer.basementType = payload.defaultBaseId
      const [firstAvailableFastener] = filterFasteners(
        payload.defaultBaseId,
        'junction',
        payload.mounting
      )
      state.defaultMountLayer.selected = firstAvailableFastener.id
      // state.defaultMountLayer.basementType = payload.defaultBaseId
    },
    UPDATE_SLOPE_CONTENT: (state, payload) => {
      Vue.set(state, 'slopeProducts', cloneDeep(payload.slope.products))
      Vue.set(state, 'slopeCategories', cloneDeep(payload.slope.categories))
      Vue.set(state, 'slopeFasteners', cloneDeep(payload.fasteners.products))
      Vue.set(state, 'slopeFastenersCategories', cloneDeep(payload.fasteners.categories))
    },
    UPDATE_STATE_ARRAY: (state, payload) => {
      Vue.set(state, [payload.param], cloneDeep(payload.array))
    },
    RESET_SECTORS: state => {
      if (state.TNToken) {
        state.TNToken = null
      }
      state.sectors.splice(0, state.sectors.length)
      state.sectors.push(cloneDeep(state.defaultSector))
    },
    UPDATE_RESULT: (state, payload) => {
      Vue.set(state.result, 'total', payload.total)
      Vue.set(state.result, 'sectors', payload.sectors)
    },
    SET_SAVED_DATA: (state, payload) => {
      // console.log(JSON.parse(payload.result))
      Vue.set(state.result, 'total', JSON.parse(payload.result).total)
      Vue.set(state.result, 'sectors', JSON.parse(payload.result).sectors)
      Vue.set(state, 'sectors', JSON.parse(payload.sectors))
      state.isFromLink = true
    },
    SET_EXTENDED_SAVED_DATA: (state, { result, sectors }) => {
      // console.log(JSON.parse(payload.result))
      Vue.set(state.result, 'total', result.total)
      Vue.set(state.result, 'sectors', result.sectors)
      Vue.set(state, 'sectors', sectors)
    },
    // //////////////////////////////////////STATE//////////////////////////////////////

    // //////////////////////////////////////SYSTEM//////////////////////////////////////

    UPDATE_SECTOR_SYSTEM: (state, payload) => {
      Vue.set(state.sectors, [payload.index], cloneDeep(state.defaultSector))
      state.sectors[payload.index].system = payload.systemId
      state.sectors[payload.index].uid = getRandomId()
      const clonedLayers = cloneDeep(payload.data.layers)
      const refactoredLayers = new Layers(clonedLayers)
      Vue.set(
        state.sectors[payload.index],
        'layers',
        cloneDeep(refactoredLayers.setComputedFastenersRate)
      )
      state.defaultSystemSlope.slopes.mainSlope.id = null
      state.defaultSystemSlope.slopes.contrSlope.id = null
      if (Object.prototype.hasOwnProperty.call(payload.data, 'slope')) {
        if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'mainSlope')) {
          state.defaultSystemSlope.slopes.mainSlope.id = payload.data.slope.mainSlope
        } else {
          state.defaultSystemSlope.slopes.mainSlope.id = null
        }
        if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'contrSlope')) {
          state.defaultSystemSlope.slopes.contrSlope.id = payload.data.slope.contrSlope
        } else {
          state.defaultSystemSlope.slopes.contrSlope.id = null
        }
      }
      // Vue.set(state.sectors[payload.index], 'layers', cloneDeep(refactoredLayers.setComputedFastenerHeight));
      // refactoredLayers.setComputedFastenerHeight
    },
    CREATE_CUSTOM_SYSTEM: (state, payload) => {
      Vue.set(state.sectors, payload, cloneDeep(state.defaultCustomSector))
      state.defaultSystemSlope.slopes.mainSlope.id = null
      state.defaultSystemSlope.slopes.contrSlope.id = null
    },
    RESTORE_SYSTEM_TO_DEFAULT: (state, payload) => {
      state.sectors[payload.sectorIndex].isCustom = false
      state.sectors[payload.sectorIndex].systemType = 'defaultSystem'
      const clonedLayers = cloneDeep(payload.data.layers)
      const refactoredLayers = new Layers(clonedLayers)
      state.sectors[payload.sectorIndex].layers = cloneDeep(
        refactoredLayers.setComputedFastenersRate
      )
      state.defaultSystemSlope.slopes.mainSlope.id = null
      state.defaultSystemSlope.slopes.contrSlope.id = null
      if (Object.prototype.hasOwnProperty.call(payload.data, 'slope')) {
        if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'mainSlope')) {
          state.defaultSystemSlope.slopes.mainSlope.id = payload.data.slope.mainSlope
        } else {
          state.defaultSystemSlope.slopes.mainSlope.id = null
        }
        if (Object.prototype.hasOwnProperty.call(payload.data.slope, 'contrSlope')) {
          state.defaultSystemSlope.slopes.contrSlope.id = payload.data.slope.contrSlope
        } else {
          state.defaultSystemSlope.slopes.contrSlope.id = null
        }
      }
    },
    UPDATE_SECTOR_SYSTEM_LAYERS_ORDER: (state, payload) => {
      state.sectors[payload.sectorIndex].layers = payload.value
    },
    UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE: (
      state,
      { sectorIndex, layerIndex, itemIndex, value }
    ) => {
      state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].material.value = value
    },
    UPDATE_SECTOR_SYSTEM_LAYER_FASTENER_PARAM: (
      state,
      { sectorIndex, layerIndex, itemIndex, param, value }
    ) => {
      state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].fasteners[param] = value
    },
    UPDATE_SECTOR_SYSTEM_LAYER_COMPUTED_FASTENER: (
      state,
      { sectorIndex, layerIndex, itemIndex, value }
    ) => {
      state.sectors[sectorIndex].layers[layerIndex].items[itemIndex].computedFastenersRate = value
    },
    // UPDATE_SECTOR_SYSTEM_LAYER_ITEM_FASTENER_ATTACH_STATUS: (state, payload) => {
    //   state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex].fasteners.isAttach = payload.value;
    // },
    // UNCHECK_ALL_SECTOR_SYSTEM_LAYERS_ITEMS_FASTENER_ATTACH: (state, payload) => {
    //   const layers = state.sectors[payload].layers;
    //   for (let i = 0; i < layers.length; i+= 1) {
    //     for (let n = 0; n < layers[i].items.length; n += 1) {
    //       if (Object.prototype.hasOwnProperty.call(layers[i].items[n], 'fasteners')) {
    //         Vue.set(state.sectors[payload].layers[i].items[n].fasteners, 'isAttach', false);
    //       }
    //     }
    //   }
    // },
    UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS: (state, payload) => {
      state.sectors[payload.sectorIndex].layers[payload.layerIndex].isEnabled = payload.value
    },
    ADD_FASTENER_TO_LAYER: (state, payload) => {
      state.defaultFastener.selected = payload.fastenerId
      // console.log(typeof(payload.fastenerRate) !== 'undefined');
      if (typeof payload.fastenerRate !== 'undefined') {
        state.defaultFastener.rate = payload.fastenerRate
      } else {
        state.defaultFastener.rate = 0
      }
      // typeof(payload.fastenerRate) !== 'undefined' ? state.defaultFastener.rate = 0 : state.defaultFastener.rate = payload.fastenerRate;
      const clonedItem = cloneDeep(
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex]
      )
      Vue.set(clonedItem, 'fasteners', cloneDeep(state.defaultFastener))
      const refactoredItem = new Item(clonedItem)
      Vue.set(
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].items,
        [payload.itemIndex],
        cloneDeep(refactoredItem.setComputedFastenersRate)
      )
      //  Vue.set(state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex], 'fasteners', cloneDeep(state.defaultFastener));
    },
    SET_COMPUTED_HEIGHT_FASTENER: (state, payload) => {
      // console.log(payload);
      const target =
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex]
          .fasteners
      if (Object.prototype.hasOwnProperty.call(target, 'computedFastenerHeight')) {
        target.computedFastenerHeight = payload.height
      } else {
        Vue.set(target, 'computedFastenerHeight', payload.height)
      }
    },
    REMOVE_FASTENER_FROM_LAYER: (state, payload) => {
      Vue.delete(
        state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex],
        'fasteners'
      )
    },
    ADD_PARENT_LAYER_TO_SYSTEM: (state, payload) => {
      const addedLayer = cloneDeep(state.layersList.find(p => p.id === payload.layerId))
      addedLayer.items.splice(0, addedLayer.items.length)
      state.sectors[payload.sectorIndex].layers.push(addedLayer)
    },
    ADD_CHILD_LAYER_TO_SYSTEM: (state, payload) => {
      const target = state.sectors[payload.sectorIndex].layers[payload.layerIndex].items
      target.push(cloneDeep(payload.layer))
      Vue.set(target[target.length - 1], 'material', {})
    },
    ADD_CHILD_LAYER_TO_SYSTEM_FROM_SEARCH: (state, { sectorIndex, layerIndex, childLayer }) => {
      state.sectors[sectorIndex].layers[layerIndex].items.push(cloneDeep(childLayer))
    },
    ADD_PARENT_LAYER_TO_SYSTEM_FROM_SEARCH: (state, { sectorIndex, newParentLayer }) => {
      state.sectors[sectorIndex].layers.push(newParentLayer)
    },
    DELETE_SECTOR_SYSTEM_CHILD_LAYER: (state, payload) => {
      state.sectors[payload.sectorIndex].layers[payload.layerIndex].items.splice(
        payload.itemIndex,
        1
      )
    },
    UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MATERIAL: (
      state,
      { sectorIndex, layerIndex, itemIndex, material }
    ) => {
      if (!material.isMechanicalFasteners) {
        Vue.delete(state.sectors[sectorIndex].layers[layerIndex].items[itemIndex], 'fasteners')
        Vue.set(
          state.sectors[sectorIndex].layers[layerIndex].items[itemIndex],
          'material',
          cloneDeep(material)
        )
      } else {
        const clonedItem = cloneDeep(state.sectors[sectorIndex].layers[layerIndex].items[itemIndex])
        Vue.set(clonedItem, 'material', cloneDeep(material))
        const refactoredItem = new Item(clonedItem)
        // console.log(refactoredItem.setComputedFastenersRate);

        Vue.set(
          state.sectors[sectorIndex].layers[layerIndex].items,
          [itemIndex],
          cloneDeep(refactoredItem.setComputedFastenersRate)
        )
      }
      const target = state.sectors[sectorIndex].layers[layerIndex]
      if (target.isBaseLayer) {
        target.items[itemIndex].baseTypeId = material.baseTypeId
      }
    },
    UPDATE_SECTOR_SYSTEM_BASE_LAYER_TYPE: (state, payload) => {
      // state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex].baseTypeId = payload.id;
      state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex].id =
        payload.id // ранее не было и это под вопросом
      state.sectors[payload.sectorIndex].layers[payload.layerIndex].items[payload.itemIndex].name =
        payload.name
    },
    UPDATE_SECTOR_WEDGE_PLATE_COUNT: (state, { sectorIndex, slopeType, plateIndex, count }) => {
      state.sectors[sectorIndex].wedge.slopes[slopeType].plates[plateIndex].count = count
    },
    UPDATE_SECTOR_WEDGE_MATERIAL: (state, payload) => {
      const target = state.sectors[payload.sectorIndex].wedge.slopes[payload.slopeType]
      const slopes = state.sectors[payload.sectorIndex].wedge.slopes
      target.id = payload.id
      Vue.set(target, 'plates', cloneDeep(payload.plates))
      state.sectors[payload.sectorIndex].wedge.glue = calculateGlueSquare(
        slopes.contrSlope.plates,
        slopes.mainSlope.plates
      )
    },
    UPDATE_SECTOR_WEDGE_GLUE: (state, payload) => {
      state.sectors[payload.sectorIndex].wedge.glue = payload.value
    },
    UPDATE_SECTOR_WEDGE_PARAM: (state, { sectorIndex, param, value }) => {
      state.sectors[sectorIndex].wedge[param] = value
    },
    ADD_WEDGE_FASTENER: (state, payload) => {
      const newFastener = { id: payload.id, count: 1 }
      state.sectors[payload.sectorIndex].wedge.fasteners.push(newFastener)
    },
    UPDATE_WEDGE_FASTENER: (state, payload) => {
      state.sectors[payload.sectorIndex].wedge.fasteners[payload.fastenerIndex].id = payload.id
    },
    UPDATE_WEDGE_FASTENER_COUNT: (state, payload) => {
      state.sectors[payload.sectorIndex].wedge.fasteners[payload.fastenerIndex].count =
        payload.value
    },
    UPDATE_WEDGE_FASTENERS_ORDER: (state, payload) => {
      state.sectors[payload.sectorIndex].wedge.fasteners = payload.value
    },

    // IMPORT_WEDGE_PRODUCTS_FROM_LINK: (state, { sectorIndex, products }) => {
    //   // const target = state.sectors[sectorIndex].wedge.slopes
    //   const defaultSlopes = cloneDeep(state.defaultSystemSlope.slopes)
    //   state.sectors[sectorIndex].wedge.slopes = Object.assign(defaultSlopes, { ...products.slopes })
    //   state.sectors[sectorIndex].wedge.fasteners = []
    //   // console.log(products)
    //   // Vue.set(target, 'contrSlope', cloneDeep(products.slopes.contrSlope))
    //   // Vue.set(target, 'mainSlope', cloneDeep(products.slopes.mainSlope))
    //   if (products.fasteners) {
    //     state.sectors[sectorIndex].wedge.fasteners = cloneDeep(products.fasteners)
    //   }
    //   if (products.square) {
    //     state.sectors[sectorIndex].wedge.square = products.square
    //   }
    // },
    IMPORT_WEDGE_PRODUCTS: (state, { sectorIndex, products }) => {
      // const target = state.sectors[sectorIndex].wedge.slopes
      const defaultSlopes = cloneDeep(state.defaultSystemSlope.slopes)
      state.sectors[sectorIndex].wedge.slopes = Object.assign(defaultSlopes, { ...products.slopes })
      state.sectors[sectorIndex].wedge.fasteners = []
      // console.log(products)
      // Vue.set(target, 'contrSlope', cloneDeep(products.slopes.contrSlope))
      // Vue.set(target, 'mainSlope', cloneDeep(products.slopes.mainSlope))
      if (products.fasteners) {
        state.sectors[sectorIndex].wedge.fasteners = cloneDeep(products.fasteners)
      }
      if (products.sectorsCount) {
        state.sectors[sectorIndex].wedge.sectorsCount = cloneDeep(products.sectorsCount)
      }
      if (products.square) {
        state.sectors[sectorIndex].wedge.square = products.square
      }
      if (products.layersSquare) {
        state.sectors[sectorIndex].wedge.glue = products.layersSquare
      }
    },

    EXPAND_LAYERS: (state, payload) => {
      for (let i = 0; i < state.sectors[payload.sectorIndex].layers.length; i += 1) {
        Vue.set(state.sectors[payload.sectorIndex].layers[i], 'expanded', false)
      }
      const target = state.sectors[payload.sectorIndex].layers[payload.layerIndex]
      Vue.set(target, 'expanded', payload.value)
    },
    COLLAPSE_LAYERS: (state, sectorIndex) => {
      for (let i = 0; i < state.sectors[sectorIndex].layers.length; i += 1) {
        Vue.set(state.sectors[sectorIndex].layers[i], 'expanded', false)
      }
    },

    // //////////////////////////////////////SECTOR//////////////////////////////////////

    ADD_NEW_SECTOR: state => {
      // state.defaultSector.sectorName = `Участок ${state.sectors.length + 1}`;
      state.defaultSector.name = `Участок ${state.sectors.length + 1}`
      state.defaultSector.uid = getRandomId()
      state.defaultCustomSector.name = `Участок ${state.sectors.length + 1}`
      state.defaultCustomSector.uid = getRandomId()
      state.sectors.push(cloneDeep(state.defaultSector))
    },
    DELETE_SECTOR: (state, payload) => {
      state.sectors.splice(payload, 1)
    },
    DELETE_LAST_SECTOR: (state, payload) => {
      state.sectors.push(cloneDeep(state.defaultSector))
      state.sectors.splice(payload, 1)
    },
    UPDATE_SECTOR_PARAM: (state, payload) => {
      state.sectors[payload.index][payload.param] = payload.value
    },
    ADD_SLOPE_TO_SECTOR: (state, payload) => {
      const clonedSlopes = cloneDeep(state.defaultSystemSlope)
      const slopes = clonedSlopes.slopes
      for (let key in slopes) {
        if (Object.prototype.hasOwnProperty.call(slopes, key)) {
          if (slopes[key].id !== null) {
            const currentPlates = cloneDeep(
              state.slopeProducts.find(p => p.id === slopes[key].id).plates
            )
            currentPlates.map(p => Vue.set(p, 'count', 0))
            Vue.set(slopes[key], 'plates', currentPlates)
          }
        }
      }
      Vue.set(state.sectors[payload], 'wedge', cloneDeep(clonedSlopes))
      // state.sectors[payload].wedge.square = state.sectors[payload].square
    },

    // //////////////////////////////////////JUNCTION////////////////////////////////////

    UPDATE_SECTOR_JUNCTION_LAYERS_ORDER: (state, payload) => {
      state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].layers = payload.value
    },
    DELETE_SECTOR_JUNCTION_CHILD_LAYER: (
      state,
      { sectorIndex, junctionIndex, layerIndex, itemIndex }
    ) => {
      state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items.splice(
        itemIndex,
        1
      )
    },
    RESTORE_JUNCTION_TO_DEFAULT: (state, payload) => {
      state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].isCustom = false
      state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].junctionType =
        'defaultJunction'
      state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].type = payload.type
      state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].count = 0
      state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].layers = cloneDeep(
        payload.junctionLayers
      )
    },
    CREATE_CUSTOM_JUNCTION: (state, { sectorIndex, replacementJunctionUid }) => {
      state.defaultCustomJunction.uid = getRandomId()
      if (replacementJunctionUid) {
        const replacementJunctionIndex = state.sectors[sectorIndex].junctions.findIndex(
          j => j.uid === replacementJunctionUid
        )
        Vue.set(
          state.sectors[sectorIndex].junctions,
          [replacementJunctionIndex],
          cloneDeep(state.defaultCustomJunction)
        )
      } else {
        state.sectors[sectorIndex].junctions.push(cloneDeep(state.defaultCustomJunction))
      }
    },
    ADD_JUNCTION_TO_SECTOR: (
      state,
      { sectorIndex, junctionId, junctionType, layers, groupId, replacementJunctionUid }
    ) => {
      state.defaultJunction.uid = getRandomId()
      state.defaultJunction.id = junctionId
      state.defaultJunction.type = junctionType
      Vue.set(state.defaultJunction, 'layers', cloneDeep(layers))
      if (groupId !== null) {
        Vue.set(state.defaultJunction, 'groupId', groupId)
      }
      if (replacementJunctionUid) {
        const replacementJunctionIndex = state.sectors[sectorIndex].junctions.findIndex(
          j => j.uid === replacementJunctionUid
        )
        Vue.set(
          state.sectors[sectorIndex].junctions,
          [replacementJunctionIndex],
          cloneDeep(state.defaultJunction)
        )
      } else {
        state.sectors[sectorIndex].junctions.push(cloneDeep(state.defaultJunction))
      }
      if (state.defaultJunction.groupId) {
        delete state.defaultJunction.groupId
      }
      Vue.set(state.defaultJunction, 'layers', [])
    },
    UPDATE_GROUP_JUNCTION_LAYERS: (state, payload) => {
      // console.log(payload);
      Vue.set(
        state.sectors[payload.index].junctions[payload.junctionIndex],
        'layers',
        cloneDeep(payload.layers)
      )
    },
    DELETE_JUNCTION: (state, payload) => {
      state.sectors[payload.sectorIndex].junctions.splice(payload.junctionIndex, 1)
    },
    // DELETE_LAST_JUNCTION: (state, payload) => {
    //   state.sectors[payload.sectorIndex].junctions.push(cloneDeep(state.defaultJunction));
    //   state.sectors[payload.sectorIndex].junctions.splice(payload.junctionIndex, 1);
    // },
    UPDATE_JUNCTION_PARAM: (state, { index, junctionIndex, param, value }) => {
      state.sectors[index].junctions[junctionIndex][param] = value
    },
    UPDATE_JUNCTION_LAYER_ITEM_VALUE: (
      state,
      { sectorIndex, junctionIndex, layerIndex, itemIndex, value }
    ) => {
      // console.log(payload);
      // prettier-ignore
      state.sectors[sectorIndex]
        .junctions[junctionIndex].layers[layerIndex].items[itemIndex].material.value = value
    },
    UPDATE_JUNCTION_LAYER_MATERIAL_PARAM: (
      state,
      { sectorIndex, junctionIndex, layerIndex, itemIndex, param, value }
    ) => {
      // prettier-ignore
      state.sectors[sectorIndex]
        .junctions[junctionIndex].layers[layerIndex].items[itemIndex][param] = value
    },
    UPDATE_JUNCTION_LAYER_ITEM_MATERIAL: (
      state,
      { sectorIndex, junctionIndex, layerIndex, itemIndex, material }
    ) => {
      Vue.set(
        state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items[itemIndex],
        'material',
        cloneDeep(material)
      )
    },
    UPDATE_JUNCTION_FASTENER_SELECTED: (
      state,
      { sectorIndex, junctionIndex, layerIndex, itemIndex, id }
    ) => {
      // prettier-ignore
      state.sectors[sectorIndex]
        .junctions[junctionIndex].layers[layerIndex].items[itemIndex].selected = id
    },
    UPDATE_JUNCTION_MOUNT_LAYER_PARAM: (
      state,
      { sectorIndex, junctionIndex, layerIndex, itemIndex, param, value }
    ) => {
      // prettier-ignore
      state.sectors[sectorIndex]
        .junctions[junctionIndex].layers[layerIndex].items[itemIndex][param] = value
    },
    ADD_PARENT_LAYER_TO_JUNCTION: (state, payload) => {
      const addedLayer = cloneDeep(state.junctionsLayersList.find(p => p.id === payload.layerId))
      addedLayer.items.splice(0, addedLayer.items.length)
      state.sectors[payload.sectorIndex].junctions[payload.junctionIndex].layers.push(addedLayer)
    },
    ADD_CHILD_LAYER_TO_JUNCTION: (state, { sectorIndex, junctionIndex, layerIndex, layer }) => {
      const target = state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items
      target.push(cloneDeep(layer))
      // target.push(cloneDeep(state.defaultJunctionLayer));
      Vue.set(target[target.length - 1], 'material', {})
      Vue.set(
        target[target.length - 1],
        'materialRate',
        cloneDeep(state.defaultJunctionLayer.materialRate)
      )
      Vue.set(target[target.length - 1], 'mark', cloneDeep(state.defaultJunctionLayer.mark))
      // Vue.set(target[target.length - 1], 'type', cloneDeep(state.defaultJunctionLayer.type));
    },
    ADD_MOUNT_LAYER_TO_JUNCTION: (state, { sectorIndex, junctionIndex, layerIndex }) => {
      // state.defaultMountLayer.selected = state.fasteners[0].id;
      state.sectors[sectorIndex].junctions[junctionIndex].layers[layerIndex].items.push(
        cloneDeep(state.defaultMountLayer)
      )
      // Vue.set()
    }
  },
  getters: {
    getSector: state => index => state.sectors[index],
    getSectorResult: state => index => state.result.sectors[index],
    getItemsByParent: state => index => state.rects[index].items
  },
  actions: {
    fetchData({ commit }, lang) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
      getContent(lang).then(response => {
        commit('UPDATE_CONTENT', response.data)
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
      })
    },
    fetchSlopeMaterials({ commit }, { lang, sectorIndex }) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.wedge, status: true })
      fetchSlopeMaterials(lang).then(response => {
        // console.log(response);
        commit('UPDATE_SLOPE_CONTENT', response.data)
        commit('ADD_SLOPE_TO_SECTOR', sectorIndex)
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.wedge, status: false })
      })
    },
    deleteFiles({ state, commit }, payload) {
      commit('UPDATE_JUNCTION_PARAM', {
        index: payload.sectorIndex,
        junctionIndex: payload.junctionIndex,
        param: 'drawingUrl',
        value: null
      })
      if (!state.isFromLink) {
        deleteDrawing(payload.files)
      }
    },
    getResults({ state, commit }, lang) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: true })
      const clonedSectors = cloneDeep(state.sectors)
      const preparedSectors = new Sectors(clonedSectors, state.defaultBaseId)
      getResults(lang, cloneDeep(preparedSectors.prepareSectorsToApi)).then(response => {
        if (state.hash !== null) {
          commit('UPDATE_HASH', null)
        }
        const normalizedResponseUnits = normalizeResponseUnits(
          response.data,
          state.junctions,
          state.sectors
        )
        commit('UPDATE_RESULT', normalizedResponseUnits)
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.result, status: false })
      })
    },
    saveResult({ state, commit }, payload) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.save, status: true })
      commit('UPDATE_USER_INFO', payload.user)
      saveResult(payload, state.sectors, state.result, state.TNToken).then(response => {
        commit('UPDATE_USER_HASH', response.data)
        commit('UPDATE_HASH', response.data)
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages.save, status: false })
      })
    },
    async getSavedProject({ commit }, { lang, hash, page, userId }) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: true })
      Promise.all([getContent(lang), fetchSlopeMaterials(lang), getSavedProject(hash, userId)])
        .then(response => {
          const [content, slopeProducts, savedProject] = response
          commit('UPDATE_CONTENT', content.data)
          commit('UPDATE_SLOPE_CONTENT', slopeProducts.data)
          commit('SET_SAVED_DATA', savedProject.data)
          if (savedProject.data.hasOwnProperty('deepSettings')) {
            commit('moderator/SET_SAVED_DEEP_SETTINGS', savedProject.data)
          }
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: false })
        })
        .catch(() => {
          router.push('/404')
        })
    },
    getPdf({ state, commit }, payload) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[payload.page], status: true })
      getPdf(
        payload,
        state.sectors,
        state.result,
        state.junctions,
        state.albums,
        state.user,
        state.albums
      ).then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' })
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file)
        commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[payload.page], status: false })

        if (isSafari()) {
          const fileName = `${payload.fileName}.pdf`
          saveAs(file, fileName)
        } else {
          // Open the URL on new Window
          window.open(fileURL)
        }
      })
    },
    getXls({ state, commit }, { lang, type, fileName, sectorIndex, junctionIndex, title, page }) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: true })
      // prettier-ignore
      const user = isEmpty(state.user)
        ? null
        : {
          objectName: state.user.objectName,
          address: state.user.address,
          link: `${location.origin}/${lang}/result/?object=${state.user.hash}`
        }
      getXls(lang, type, state.result, state.sectors, user, sectorIndex, junctionIndex, title).then(
        response => {
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: false })

          const newFileName = `${fileName}.xlsx`
          saveAs(file, newFileName)
        }
      )
    },
    deleteSectorFromDetailsPage({ state, commit }, { sectorIndex, lang }) {
      commit('DELETE_SECTOR', sectorIndex)
      state.result = {}
      router.push(`/${lang}/result`)
      if (state.sectors.length === 0) {
        state.sectors.push(cloneDeep(state.defaultSector))
        // router.push(`/${lang}/`)
      }
    },
    deleteJunctionFromDetailsPage({ state, commit }, { sectorIndex, junctionIndex, lang }) {
      commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
      state.result = {}
      router.push(`/${lang}/result`)
    },
    deleteJunctionFromPage({ state, commit }, { sectorIndex, junctionIndex, lang }) {
      const currentJunctions = state.sectors[sectorIndex].junctions
      if (currentJunctions.length === 1) {
        router.push(`/${lang}/junctions/${sectorIndex}`)
        commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
      } else if (currentJunctions.length - 1 === Number(junctionIndex)) {
        router.push(`/${lang}/system/${sectorIndex}/junction/${junctionIndex - 1}`)
        commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
      } else {
        commit('DELETE_JUNCTION', { sectorIndex, junctionIndex })
      }
    },
    transformJunctionToCustom(
      { state, commit },
      { sectorIndex, junctionIndex, isSaveDrawing, transformations, originalDrawingUrl }
    ) {
      Object.entries(transformations).forEach(entry => {
        const [param, value] = entry
        commit('UPDATE_JUNCTION_PARAM', {
          index: sectorIndex,
          junctionIndex,
          param,
          value
        })
      })
      if (isSaveDrawing) {
        commit('UPDATE_JUNCTION_PARAM', {
          index: sectorIndex,
          junctionIndex,
          param: 'drawingUrl',
          value: originalDrawingUrl
        })
      }
      // transformations.forEach(t => {
      //   commit('UPDATE_JUNCTION_PARAM', {
      //     index: sectorIndex,
      //     junctionIndex,
      //     param: t.param,
      //     value: t.value
      //   })
      // })
    },
    getAllDataForDeepSettingsPage({ state, commit }, { lang, hash, page, userId }) {
      commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: true })
      const isNeedDeepSettingsData = isEmpty(state.moderator.customerTypes)
      const defaultQueries = [
        getContent(lang),
        fetchSlopeMaterials(lang),
        getSavedProject(hash, userId)
      ]
      const requiredQueries = isNeedDeepSettingsData
        ? [...defaultQueries, getDeepSettingsData(lang)]
        : defaultQueries
      Promise.all([...requiredQueries])
        .then(response => {
          const [content, slopeProducts, savedProject, deepSettingsData] = response
          commit('UPDATE_CONTENT', content.data)
          commit('UPDATE_SLOPE_CONTENT', slopeProducts.data)
          commit('SET_SAVED_DATA', savedProject.data)
          if (isNeedDeepSettingsData) {
            commit('moderator/SET_DEEP_SETTINGS_DATA', deepSettingsData.data)
          }
          commit('moderator/SET_SAVED_DEEP_SETTINGS', savedProject.data)
          commit('UPDATE_PAGE_LOADING_STATUS', { page: pages[page], status: false })
        })
        .catch(() => {
          router.push('/404')
        })
    },
    updateTNToken({ commit }) {
      commit('UPDATE_TN_TOKEN_STATUS', true)
    },
    checkTNToken({ commit }, token) {
      checkTNToken(token)
        .then(() => {
          commit('UPDATE_TN_TOKEN', token)
        })
        .catch(() => {
          commit('UPDATE_TN_TOKEN_STATUS', true)
        })
    },
    updateStep({ state, commit, dispatch }, stepNumber) {
      commit('UPDATE_STEP_NUMBER', stepNumber)
      if (state.sessionToken && stepNumber) {
        dispatch('sendMetrics')
      } else {
        commit(
          'UPDATE_SESSION_TOKEN',
          this.sessionToken = uuid.v1()
        )
        if (stepNumber) {
          dispatch('sendMetrics')
        }
      }
    },
    async sendMetrics({ state }) {
      const { user, sectors, result, sessionToken, stepNumbers } = state

      try {
        await sendMetrics(sessionToken, stepNumbers, user, sectors, result)
      } catch (e) {}
    }
  }
})
