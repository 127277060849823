import {
  applicationDataKeys,
  customerOrClientsDataKeys,
  objectDataKeys
} from '@/utils/deepSettings'
import { cloneDeep } from 'lodash'
import { calculationPriceStepItems } from '@/utils/calculationPriceStep'

const initialCalculationPrice = cloneDeep(calculationPriceStepItems)

export const applicationData = 'applicationData'
export const objectData = 'objectData'
export const calculationAccuracy = 'calculationAccuracy'
export const calculationPrice = 'calculationPrice'
export const customerClientData = 'customerClientData'
export const files = 'files'

export const initialDeepSettingsState = {
  [applicationData]: {
    [applicationDataKeys.startDate]: new Date(),
    [applicationDataKeys.endDate]: new Date(),
    [applicationDataKeys.applicationNumber]: '',
    [applicationDataKeys.promoCode]: '',
    [applicationDataKeys.variantsCount]: 1,
    [applicationDataKeys.variantNumber]: 1,
    [applicationDataKeys.calculationType]: null,
    [applicationDataKeys.calculationNumber]: '',
    [applicationDataKeys.cipher]: '',
    [applicationDataKeys.cloneIndex]: 1
  },
  [objectData]: {
    [objectDataKeys.name]: '',
    [objectDataKeys.address]: '',
    [objectDataKeys.CRMNumber]: '',
    [objectDataKeys.stage]: null,
    [objectDataKeys.objectRegion]: null,
    [objectDataKeys.square]: null,
    [objectDataKeys.sectorsCount]: null
  },
  [customerClientData]: {
    [customerOrClientsDataKeys.customer]: null,
    [customerOrClientsDataKeys.position]: '',
    [customerOrClientsDataKeys.name]: '',
    [customerOrClientsDataKeys.email]: '',
    [customerOrClientsDataKeys.phone]: '',
    [customerOrClientsDataKeys.city]: '',
    [customerOrClientsDataKeys.INN]: '',
    [customerOrClientsDataKeys.KPP]: '',
    [customerOrClientsDataKeys.clientType]: null,
    [customerOrClientsDataKeys.organization]: '',
    [customerOrClientsDataKeys.clientRegion]: null,
    [customerOrClientsDataKeys.other]: ''
  },
  [calculationAccuracy]: {},
  [calculationPrice]: initialCalculationPrice,
  [files]: {},
  status: null
}
