<template>
  <header class="header" :class="{ 'header--full-width': isFullWidth }">
    <div class="header__content">
      <a href="https://nav.tn.ru/" target="_blank" class="header__content-logo">
        <img src="@/assets/img/logo.svg" alt="logo" class="picture--desktop" />
        <img src="@/assets/img/logo--mini.svg" alt="logo" class="picture--mobile" />
      </a>
      <div class="header__content-title">
        {{ $t('message.header.title') }}
      </div>
      <div :class="buttonsWrapperClasses">
        <button @click="toggleButtons" class="close">
          <img src="@/assets/img/close-red.svg" alt="❌" />
        </button>
        <a href="https://bit.ly/2O0QIGH" target="_blank" class="link">
          <img src="@/assets/img/icons/header-error.svg" alt="!" class="link__icon" />
          <i18n path="message.header.error" tag="span" />
        </a>
        <a href="https://nav.tn.ru/calculators/embed/" target="_blank" class="link">
          <img src="@/assets/img/icons/header-code.svg" alt="</>" class="link__icon" />
          <i18n path="message.header.coder" tag="span" />
        </a>
      </div>
      <header-user v-if="isShow" />
      <button @click="toggleButtons" class="header__content-toggle">
        <img src="@/assets/img/menu.svg" alt=":" />
      </button>
    </div>
  </header>
</template>

<script>
import HeaderUser from '@/components/smart/HeaderUser'
const routesWithoutUserBtn = ['Save']
export default {
  props: {
    isFullWidth: Boolean
  },
  components: { HeaderUser },
  data: () => ({
    isShowButtons: false
  }),
  methods: {
    toggleButtons() {
      this.isShowButtons = !this.isShowButtons
    }
  },
  computed: {
    buttonsWrapperClasses() {
      return {
        'header__content-buttons': true,
        'header__content-buttons--active': this.isShowButtons
      }
    },
    isShow() {
      return !routesWithoutUserBtn.includes(this.$route.name)
    }
  }
}
</script>

<style lang="sass" scoped>
header.header
  background: $white
  z-index: 4
  position: relative
  &.header--full-width
    .header
      &__content
        max-width: 100%
        +media((padding: (0: rem(12) 0 rem(12) rem(20), 768: rem(22) rem(50))))
  .header
    &__content
      width: 100%
      max-width: rem(1200)
      margin: 0 auto
      display: flex
      align-items: center
      +media((padding: (0: rem(12) 0 rem(12) rem(20), 768: rem(22))))
      box-sizing: border-box
      &-logo
        display: flex
        .picture
          &--mobile
            +media((display: (992: none)))
          &--desktop
            +media((display: (0: none, 992: block)))
      &-title
        font-style: normal
        font-weight: normal
        +media((font-size: (0: rem(16), 992: rem(20), 1200: rem(24))))
        line-height: 100%
        color: $black
        +media((margin-left: (0: rem(20), 992: rem(30))))
        +media((padding-left: (992: rem(30))))
        +media((box-shadow: (992: inset rem(1) 0 0 $ec)))
        padding-right: rem(10)
      &-buttons
        +media((opacity: (0: none, 768: 1)))
        +media((pointer-events: (0: none, 768: auto)))
        +media((display: (0: none, 768: flex)))
        +media((top: (0: rem(16), 768: unset)))
        +media((right: (0: rem(16), 768: unset)))
        +media((margin: (768: 0 rem(20) 0 auto)))
        +media((padding: (0: rem(26), 768: 0)))
        gap: rem(4)
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
        +media((flex-direction: (0: column, 768: row)))
        +media((position: (0: absolute, 768: relative)))
        +media((box-shadow: (0: 0 rem(4) rem(14) rem(2) rgba($black, .16), 768: none)))
        +media((border-radius: (0: rem(4), 768: 0)))
        +media((background: (320: $white, 768: none)))
        &--active
          display: flex
          opacity: 1
          pointer-events: auto
        .close
          @extend .clear-btn
          position: absolute
          right: 0
          top: 0
          padding: rem(8)
          +media((display: (768: none)))
          img
            height: rem(10)
            width: auto
        .link
          background: rgba($ec, .5)
          border-radius: rem(4)
          padding: rem(8) rem(10)
          font-style: normal
          font-weight: 600
          font-size: rem(12)
          line-height: rem(16)
          color: $dark-grey
          text-decoration: none
          display: flex
          align-items: center
          transition: .5s
          &__icon
            margin-right: rem(10)
            filter: invert(64%) sepia(0%) saturate(1280%) hue-rotate(350deg) brightness(94%) contrast(94%)
            transition: .5s
          @media(any-hover: hover)
            &:hover
              color: $black
              background: $ec
              .link
                &__icon
                  filter: invert(25%) sepia(72%) saturate(6603%) hue-rotate(347deg) brightness(81%) contrast(117%)
      &-toggle
        @extend .clear-btn
        height: rem(40)
        width: rem(40)
        flex: 0 0 rem(40)
        +media((display: (768: none)))
      &-user
        +media((margin-left: (0: auto, 768: unset)))
//.header
  &__logo
    +media((padding-left: (320: rem(16), 992: 0)))
  &__content
    display: grid
    width: 100%
    +media((grid-template-columns: (320: 12fr 2fr, 992: 8fr 3fr)))
    &-title
      font-style: normal
      font-weight: bold
      color: $black
      text-align: left
      padding-top: rem(10)
      +media((font-size: (320: rem(18), 992: rem(28))))
      +media((line-height: (320: rem(28), 992: rem(34))))
      +media((padding-left: (320: rem(16), 992: 0)))
    &-menu
      +media((display: (320: flex, 992: none)))
      padding-right: rem(24)
      padding-top: rem(10)
      position: relative
      flex-direction: row
      justify-content: center
      align-items: center
      &--btn
        background: none
        box-shadow: none
        border: none
        outline: none
    &-btns
      +media((display: (320: none, 992: flex)))
      padding-top: rem(10)
      position: relative
    &-modal
      padding: rem(26) rem(30) rem(26) rem(30)
      position: absolute
      top: 0
      right: rem(24)
      z-index: 2
    &-btns, &-modal
      align-items: center
      +media((flex-direction: (320: column, 992: row)))
      +media((box-shadow: (320: 0 rem(4) rem(14) rem(2) rgba(0, 0, 0, 0.16), 992: none)))
      +media((border-radius: (320: rem(4), 992: 0)))
      +media((background: (320: #fff, 992: none)))
      +media((align-items: (320: flex-start, 992: center)))
      +media((justify-content: (320: center, 992: flex-end)))
      &--close
        background: none
        box-shadow: none
        outline: none
        border: none
        padding: 0
        position: absolute
        top: 0
        right: 0
        line-height: 0
        padding: rem(8)
        img
          height: rem(10)
      &--error
        +media((padding-right: (320: 0, 992: rem(20))))
      &--error, &--code
        display: flex
        flex-direction: row
        align-items: center
        cursor: pointer
        text-decoration: none
        img
          padding-right: rem(11)
        span
          font-style: normal
          font-weight: 500
          font-size: rem(12)
          line-height: rem(15)
          color: #999999
          text-align: left
          +media((padding: (320: rem(8) 0, 992: 0)))
</style>
