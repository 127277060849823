const colorHashes = {
  red: '#E00F20',
  yellow: '#F5BD2D',
  green: '#3DA915'
}
export const gradesValues = {
  bad: 'bad',
  neutral: 'neutral',
  good: 'good',
  idea: 'idea'
}

export const colors = {
  [gradesValues.bad]: colorHashes.red,
  [gradesValues.neutral]: colorHashes.yellow,
  [gradesValues.good]: colorHashes.green,
  [gradesValues.idea]: colorHashes.red
}

export const fields = {
  name: {
    name: 'name'
    // placeholder: '',
    // label: ''
  },
  email: {
    name: 'email'
    // placeholder: '',
    // label: ''
  },
  message: {
    name: 'message',
    // placeholder: '',
    // label: '',
    textarea: true
  }
}

export const errors = {
  required: 'required',
  email: 'email'
}
