<template>
  <button @click="handler" class="button">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'LoginBtn',
  methods: {
    handler() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="sass">
.button
  @extend .clear-btn
  height: rem(40)
  width: rem(40)
  flex: 0 0 rem(40)
</style>
