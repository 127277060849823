import Vue from 'vue'
import { i18n } from './plugins/i18n.js'
import VueTippy, { TippyComponent } from 'vue-tippy'
import MetaInfo from 'vue-meta-info'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import VueLoading from 'vue-loading-overlay'
import UnsupportBrowsers from '@/UnsupportBrowsers'
import App from '@/App'
import router from './router'
import store from './store'

import 'swiper/dist/css/swiper.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'tippy.js/themes/light.css'

Vue.use(MetaInfo)
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)
Vue.use(VueLoading)
Vue.component('loading', VueLoading)
Vue.use(VueAwesomeSwiper)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.config.productionTip = false

const $body = document.querySelector('body')
let scrollPosition = 0
// Internet Explorer 6-11
const isIE = !!document.documentMode
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia

if (!isIE && !isEdge) {
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    methods: {
      onBodyScroll(isNeedReturn = true) {
        // document.body.classList.remove('modal-open');
        this.enabledScroll(isNeedReturn)
      },
      offBodyScroll() {
        // document.body.classList.add('modal-open');
        this.disableScroll()
      },
      disableScroll() {
        scrollPosition = window.pageYOffset
        $body.style.overflow = 'hidden'
        $body.style.position = 'fixed'
        // $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%'
      },
      enabledScroll(isNeedReturn) {
        $body.style.removeProperty('overflow')
        $body.style.removeProperty('position')
        // $body.style.removeProperty('top');
        $body.style.removeProperty('width')
        if (isNeedReturn) {
          window.scrollTo(0, scrollPosition)
        }
      }
    }
  }).$mount('#app')
  Vue.directive('click-outside', {
    bind(el, binding) {
      el.addEventListener('click', e => e.stopPropagation())
      document.body.addEventListener('click', binding.value)
    },
    unbind(el, binding) {
      document.body.removeEventListener('click', binding.value)
    }
  })
} else {
  new Vue({
    i18n,
    router,
    render: h => h(UnsupportBrowsers),
    methods: {},
    mounted() {}
  }).$mount('#app')
}
