<template>
  <footer ref="footer">
    <div class="container">
      <div class="footer__items">
        <div class="footer__items-single footer__items-single--first">
          <a href="https://nav.tn.ru/" target="_blank" class="footer__logo">
            <img src="@/assets/img/logo.svg" alt="logo" />
          </a>
          <footer-user />
        </div>
        <div class="footer__items-single">
          <a
            :href="link.url"
            v-for="link in this.$t('message.footer').links"
            :key="link.title"
            target="_blank"
            class="footer__link"
          >
            {{ link.title }}
          </a>
          <!--<a href="https://shop.tn.ru/teploizoljacija" target="_blank"
             class="footer__link">Где купить?</a>
          <a href="https://nav.tn.ru/services/proektno-raschetnyy-tsentr/form/"
             target="_blank" class="footer__link">
            Заказать профессиональный расчет в ПРЦ
          </a>
          <a href="https://nav.tn.ru/services/support/" target="_blank" class="footer__link">
            Задать вопрос эксперту
          </a>-->
        </div>
        <div class="footer__items-single">
          <div class="footer__btns">
            <a href="https://bit.ly/2O0QIGH" class="footer__error" target="_blank">
              <img src="@/assets/img/alert.svg" alt="!" />
              <!--<span v-html="$t('footer.error')"></span>-->
              <span>
                <i18n path="message.footer.error">
                  <template #break>
                    <br />
                  </template>
                </i18n>
              </span>
            </a>
            <a href="https://nav.tn.ru/calculators/embed/" class="footer__code" target="_blank">
              <img src="@/assets/img/coder.svg" alt="<>" />
              <!--<span v-html="$t('footer.coder')"></span>-->
              <span>
                <i18n path="message.footer.coder">
                  <template #break>
                    <br />
                  </template>
                </i18n>
              </span>
            </a>
          </div>
          <a href="http://industrialmedia.com.ua/" target="_blank" class="footer__madeBy">
            <img src="@/assets/img/logo-im.svg" alt="IndustrialMedia" />
          </a>
          <!-- <div v-for="lang in languages" :key="lang">
            <label :for="lang"
              >{{ lang }}
              <input
                :checked="$i18n.locale === lang"
                type="radio"
                :value="lang"
                :id="lang"
                name="lang"
                @change="updateLang(lang)"
              />
            </label>
          </div> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterUser from '@/components/smart/FooterUser'
const languages = ['ru', 'en']
export default {
  components: { FooterUser },
  methods: {
    updateLang(lang) {
      const { $i18n, $set, setLocale } = this

      if (!$i18n.vm.messages[lang]) {
        // eslint-disable-next-line
        import(`@/translations/locales/${lang}.js`).then(language => {
          // console.log($set(this.$i18n.vm.messages, lang, {...language.default}));
          $set($i18n.vm.messages, lang, { ...language.default })
          setLocale(lang)
        })
      } else {
        setLocale(lang)
      }
    },
    setLocale(lang) {
      this.$i18n.locale = lang
      // localStorage.setItem('lang', lang);
      this.$router.push({
        params: { lang: lang }
      })
    }
  },
  computed: {
    languages: () => languages
  }
}
</script>

<style lang="sass" scoped>
footer
  background: #F6F6F6
  border: rem(1) solid #ECECEC
  box-sizing: border-box
  +media((padding: (320: rem(32) rem(24), 768: rem(40) 0)))
  .footer
    &__logo
      display: block
      +media((margin-bottom: (320: rem(30), 768: 0)))
      img
        margin: 0
        float: left
    &__items
      display: grid
      grid-column-gap: rem(30)
      +media((padding: (320: 0, 768: 0 rem(15))))
      +media((grid-template-columns: (320: 1fr, 768: 3fr 5fr 4fr, 960: 3fr 6fr 3fr)))
      &-single
        &--first
          flex-direction: column
          display: flex
          justify-content: space-between
    &__link
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(20)
      color: $red
      text-decoration: none
      cursor: pointer
      display: block
      text-align: left
      +media((margin-bottom: (320: rem(12), 768: rem(14))))
      &:first-of-type
        +media((padding-top: (320: rem(30), 768: 0)))
    &__btns
      display: flex
      flex-direction: row
      align-items: center
      +media((padding-top: (320: rem(11), 768: 0)))
    &__error
      padding-right: rem(20)
    &__error, &__code
      display: flex
      flex-direction: row
      align-items: center
      cursor: pointer
      text-decoration: none
      img
        padding-right: rem(11)
      span
        font-style: normal
        font-weight: 500
        font-size: rem(12)
        line-height: rem(15)
        color: #999999
        text-align: left
    &__madeBy
      display: block
      +media((margin-top: (320: rem(34), 768: rem(40))))
      img
        margin: 0
        float: left
</style>
