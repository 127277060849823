<template>
  <div class="user-login">
    <div class="user-login__title">Вход для расчетчика</div>
    <form @submit.prevent="checkForm" novalidate class="user-login__form">
      <app-input
        :field="fields.email"
        v-model.trim="$v.email.$model"
        @input="delayTouch($v.email)"
        :is-error="$v.email.$error"
      >
        <template #errors>
          <error v-if="!$v.email.required">
            {{ errors.required }}
          </error>
          <error v-if="!$v.email.email">
            {{ errors.email }}
          </error>
        </template>
      </app-input>
      <app-input
        :field="fields.password"
        type="password"
        v-model.trim="$v.password.$model"
        @input="delayTouch($v.password)"
        :is-error="$v.password.$error"
      >
        <template #errors>
          <error v-if="!$v.password.required">
            {{ errors.required }}
          </error>
        </template>
      </app-input>
      <div class="user-login__error" v-if="isShowFormError">
        <error> Не верный логин или пароль. Проверьте правильность заполнения полей. </error>
      </div>
      <simple-button color="red" type="submit" reverse="reverse">
        Войти
        <template #icon>
          <img src="@/assets/img/icons/login-arrow.svg" alt="→" class="icon" />
        </template>
        <loading
          :active.sync="isLoading"
          :can-cancel="false"
          color="#fff"
          :height="30"
          :width="30"
          :opacity="1"
          backgroundColor="transparent"
          :z-index="3"
          :is-full-page="false"
        ></loading>
      </simple-button>
    </form>
  </div>
</template>

<script>
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import SimpleButton from '@/components/elements/Dom/Simple-button'
import AppInput from '@/components/dump/AppInput'
import Error from '@/components/smart/CalculatorRating/error'
import { errors } from '@/utils/rating'
import { loginMethod } from '@/api/moderator'
import { handleError } from '@/utils/deepSettings'

const fields = {
  email: {
    name: 'login-email'
  },
  password: {
    name: 'login-password'
  }
}

const touchMap = new WeakMap()
const emailValidations = {
  email,
  required
}
const passwordValidations = {
  required
}

const validationsRules = {
  email: emailValidations,
  password: passwordValidations
}

export default {
  name: 'UserLogin',
  components: { Error, AppInput, SimpleButton, loading },
  data: () => ({
    email: '',
    password: '',
    isLoading: false,
    isShowFormError: false
  }),
  mixins: [validationMixin],
  validations() {
    return validationsRules
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    checkForm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        this.isShowFormError = false
        const { email, password } = this
        loginMethod({
          email,
          password
        })
          .then(data => {
            this.isLoading = false
            this.$emit('logIn', data)
          })
          .catch(e => {
            if (e.response.status === 400) {
              this.isShowFormError = true
            } else {
              handleError(this.$notify, e)
            }
            this.isLoading = false
          })
      }
    }
  },
  computed: {
    fields: () => fields,
    errors() {
      return Object.fromEntries(
        Object.entries(errors).map(([key, val]) => [
          key,
          this.$t(`message.calculatorRating.errors.${val}`)
        ])
      )
    }
  }
}
</script>

<style scoped lang="sass">
.user-login
  width: 90vw
  max-width: rem(420)
  +media((padding: (0: rem(30), 768: rem(56) rem(60))))
  box-sizing: border-box
  &__error
    .error
      position: relative
      transform: none
  &__title
    font-style: normal
    font-weight: normal
    +media((font-size: (0: rem(20), 400: rem(24))))
    +media((line-height: (0: 130%, 400: rem(32))))
    color: $black
    +media((margin-bottom: (0: rem(20), 400: rem(24))))
  &__form
    display: grid
    gap: rem(8)
    .simple-btn
      width: auto
      margin-left: auto
      margin-top: rem(16)
      height: rem(48)
      position: relative
      overflow: hidden
      .vld-overlay
        line-height: 0
</style>
