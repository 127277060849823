// import { items } from '@/utils/dashboardTable'
import axios from 'axios'
import { api } from '@/api/index'
import { initialDeepSettingsState } from '@/utils/InitialDeepSettingsState'
import { cloneDeep } from 'lodash'
import { convertISOtoDate } from '@/utils/DateConverters'
import { parametersNames } from '@/utils/calculationPriceStep'

axios.defaults.withCredentials = true

export const userApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://tn-krov-stage.industrialmedia.com.ua/action/user/'
      : `${location.origin}/action/user/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: true
})

export const loginMethod = ({ email, password }) => {
  return userApi.post('/login?_format=json', {
    name: email,
    pass: password
  })
}

export const getUserId = () => {
  return api.get('/login_status')
}

export const checkIsLoggedIn = () => {
  return userApi.get('/login_status?_format=json')
}

export const logoutMethod = ({ csrfToken, logoutToken }) => {
  return userApi.post(
    `/logout?_format=json&token=${logoutToken}`,
    {},
    {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    }
  )
}

export const getApplications = ({ lang, range, userId }) => {
  const { start, end } = range
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('start', Date.parse(start))
  formData.append('end', Date.parse(end))
  formData.append('uid', userId)
  return api.post('/admin/get_results', formData)
}

export const deleteApplications = selectedApplications => {
  const formData = new FormData()
  formData.append('data', JSON.stringify(selectedApplications))
  return api.post('/admin/delete_results', formData)
}

export const getDeepSettingsData = lang => {
  const formData = new FormData()
  formData.append('lang', lang)
  return api.post('/admin/content', formData)
}
const createCostByParams = ({ lang, payload, hash, deepSettings = initialDeepSettingsState }) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('hash', hash)
  const { applicationData } = deepSettings
  const { calculationType } = applicationData
  const { variantsCount } = applicationData
  formData.append('qty', variantsCount)
  formData.append('calcType', calculationType)
  formData.append('data', JSON.stringify(payload))
  return formData
}
export const getCostByParams = ({
  lang,
  payload,
  hash,
  deepSettings = initialDeepSettingsState
}) => {
  const formData = createCostByParams({
    lang,
    payload,
    hash,
    deepSettings
  })
  return api.post('/admin/get_price', formData)
}

export const exportDataFromExcel = file => {
  const formData = new FormData()
  formData.append('file', file)
  return api.post('/admin/excel_import', formData)
}

const createCommonFormDataForExtendedSave = ({
  lang,
  userId,
  sectors,
  result,
  deepSettings = initialDeepSettingsState
}) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('uid', userId)
  formData.append('data', JSON.stringify(sectors))
  formData.append('result', JSON.stringify(result))
  formData.append('deepSettings', JSON.stringify(deepSettings))
  const { applicationData, objectData, customerClientData } = deepSettings
  const { endDate, applicationNumber, cipher } = applicationData
  const { name, square } = objectData
  const { customer } = customerClientData
  formData.append('created', Date.parse(endDate))
  formData.append('id_number', applicationNumber)
  formData.append('serial_number', `${cipher}`)
  formData.append('object_name', name)
  if (customer) {
    formData.append('customer', customer)
  }
  if (square) {
    formData.append('square', square)
  }
  return formData
}

export const saveExtendedCalculation = ({
  lang,
  userId,
  sectors,
  result,
  deepSettings = initialDeepSettingsState
}) => {
  const formData = createCommonFormDataForExtendedSave({
    lang,
    userId,
    sectors,
    result,
    deepSettings
  })
  formData.append('action', 'create')
  return api.post('/admin/save_result', formData)
}

export const updateCalculation = ({
  lang,
  userId,
  sectors,
  result,
  deepSettings,
  action,
  hash,
  isDraft = false
}) => {
  const formData = createCommonFormDataForExtendedSave({
    lang,
    userId,
    sectors,
    result,
    deepSettings
  })
  formData.append('action', action)
  formData.append('hash', hash)
  if (isDraft) {
    formData.append('isDraft', '1')
  }
  return api.post('/admin/save_result', formData)
}

export const copyCalculation = ({ lang, userId, hash, action, DeepSettings }) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('hash', hash)
  formData.append('uid', userId)
  formData.append('action', action)
  formData.append('deepSettings', JSON.stringify(DeepSettings))
  return api.post('/admin/save_result', formData)
}

export const checkIsCalculationBelongsToUser = (userId, hash) => {
  const formData = new FormData()
  formData.append('uid', userId)
  formData.append('hash', hash)
  return api.post('/admin/access_content', formData)
}

export const getExtendedPdf = ({ lang, ...etc }) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('type', 'total')
  formData.append('data', JSON.stringify(prepareDateForPdfExports({ ...etc })))
  return api.post('/admin/pdf', formData, {
    responseType: 'blob' // Force to receive data in a Blob Format
  })
}

export const getExtendedXls = ({ lang, ...etc }) => {
  const domen = `${etc.domenName}/result/?object=`
  const hash = etc.hash
  const info = {}
  info.objectName = etc.deepSettings.objectData.name
  info.address = etc.deepSettings.objectData.address
  info.link = `${domen}${hash}`
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('type', 'total')
  formData.append('sectors', JSON.stringify(etc.sectors))
  formData.append('result', JSON.stringify(prepareDateForExelExports({ ...etc })))
  formData.append('data', JSON.stringify({ ...etc.result }))
  formData.append('info', JSON.stringify(info))
  return api.post('/admin/excel_export', formData, {
    responseType: 'blob' // Force to receive data in a Blob Format
  })
}

export const getRegistry = ({ lang, selectedApplications }) => {
  const formData = new FormData()
  formData.append('lang', lang)
  formData.append('hash', JSON.stringify(selectedApplications))
  return api.post('/admin/excel_registry', formData, {
    responseType: 'blob' // Force to receive data in a Blob Format
  })
}
const prepareDateForExelExports = ({
  deepSettings,
  regions,
  stages,
  calculationTypes,
  accuracyStepData,
  sectors,
  result,
  hash,
  domenName
}) => {
  // const domen = `${domenName}/result/?object=`
  const clonedDeepSettings = cloneDeep(deepSettings)
  const { applicationData, objectData, calculationAccuracy, calculationPrice } = clonedDeepSettings
  const { startDate, endDate, applicationNumber, cipher, calculationType } = applicationData
  const { CRMNumber, name, address, objectRegion, square, stage } = objectData
  // const header = {}
  // const user = {}
  // let junctionsCount = 0
  // result.sectors.forEach(sector => {
  //   if (sector.junctions) {
  //     junctionsCount += sector.junctions.length
  //   }
  // })
  // user.hash = hash
  // header.junctionsCount = junctionsCount
  // header.sectorsCount = sectorsCount
  // header.square = square
  let addressObject
  let currentStage
  if (address || objectRegion) {
    addressObject = [address, regions.find(region => region.id === objectRegion).name || 'н / д']
  } else {
    addressObject = ['Не указан', 'Не указан']
  }
  if (stage) {
    currentStage = stages.find(s => s.id === stage).name
  } else {
    currentStage = 'Не указана'
  }
  const commonDataTable = [
    {
      title: 'Дата поступления исходных данных',
      val: convertISOtoDate(startDate)
    },
    {
      title: 'Дата выдачи расчета',
      val: convertISOtoDate(endDate)
    },
    {
      title: 'Номер заявки',
      val: applicationNumber
    },
    {
      title: 'Шифр расчета',
      val: cipher
    },
    {
      title: '№ CRM',
      val: CRMNumber
    },
    {
      title: 'Объект',
      val: name
    },
    {
      title: 'Адрес объекта/ Регион объекта',
      val: addressObject
    },
    {
      title: 'Площадь конструкции, м²',
      val: square
    },
    {
      title: 'Текущая стадия',
      val: currentStage
    },
    {
      title: 'Тип расчета',
      val: calculationTypes.find(ct => ct.id === calculationType).name
    }
  ]
  const accuracyTable = Object.entries(calculationAccuracy).map(([itemId, optionId]) => {
    const currentItem = accuracyStepData.find(item => item.id === Number(itemId))
    const currentOption = currentItem.options.find(option => option.id === optionId)
    return {
      title: currentItem.name,
      val: currentOption?.name || 'Нет',
      // prettier-ignore
      chars: currentOption?.chars
        ? currentOption.chars.map(char => {
          return char.value ? `${char.name}: ${char.value}` : char.name
        })
        : ['—']
    }
  })
  const priceTable = Object.entries(calculationPrice)
    .filter(([_, val]) => {
      return val.isEnabled
    })
    .map(([key, val]) => {
      const { complexity, discount, cost } = val
      const price = (cost * (100 - discount)) / 100
      return {
        title: parametersNames[key],
        complexity: complexity || '—',
        cost,
        discount,
        total: price
      }
    })

  return {
    commonDataTable,
    accuracyTable,
    priceTable
  }
}
const prepareDateForPdfExports = ({
  deepSettings,
  regions,
  stages,
  calculationTypes,
  accuracyStepData,
  sectors,
  result,
  hash,
  domenName
}) => {
  const domen = `${domenName}/result/?object=`
  const clonedDeepSettings = cloneDeep(deepSettings)
  const { applicationData, objectData, calculationAccuracy, calculationPrice } = clonedDeepSettings
  const { startDate, endDate, applicationNumber, cipher, calculationType } = applicationData
  const { CRMNumber, name, address, objectRegion, square, stage, sectorsCount } = objectData
  const header = {}
  const user = {}
  let junctionsCount = 0
  result.sectors.forEach(sector => {
    if (sector.junctions) {
      junctionsCount += sector.junctions.length
    }
  })
  user.hash = hash
  header.junctionsCount = junctionsCount
  header.sectorsCount = sectorsCount
  header.square = square
  let addressObject
  let currentStage
  if (address || objectRegion) {
    addressObject = [address, regions.find(region => region.id === objectRegion).name || 'н / д']
  } else {
    addressObject = ['Не указан', 'Не указан']
  }
  if (stage) {
    currentStage = stages.find(s => s.id === stage).name
  } else {
    currentStage = 'Не указана'
  }
  const commonDataTable = [
    {
      title: 'Дата поступления исходных данных',
      val: convertISOtoDate(startDate)
    },
    {
      title: 'Дата выдачи расчета',
      val: convertISOtoDate(endDate)
    },
    {
      title: 'Номер заявки',
      val: applicationNumber
    },
    {
      title: 'Шифр расчета',
      val: cipher
    },
    {
      title: '№ CRM',
      val: CRMNumber
    },
    {
      title: 'Объект',
      val: name
    },
    {
      title: 'Адрес объекта/ Регион объекта',
      val: addressObject
    },
    {
      title: 'Площадь конструкции, м²',
      val: square
    },
    {
      title: 'Текущая стадия',
      val: currentStage
    },
    {
      title: 'Тип расчета',
      val: calculationTypes.find(ct => ct.id === calculationType).name
    }
  ]
  const accuracyTable = Object.entries(calculationAccuracy).map(([itemId, optionId]) => {
    const currentItem = accuracyStepData.find(item => item.id === Number(itemId))
    const currentOption = currentItem.options.find(option => option.id === optionId)
    return {
      title: currentItem.name,
      val: currentOption?.name || 'Нет',
      // prettier-ignore
      chars: currentOption?.chars
        ? currentOption.chars.map(char => {
          return char.value ? `${char.name}: ${char.value}` : char.name
        })
        : ['—']
    }
  })
  const priceTable = Object.entries(calculationPrice)
    .filter(([_, val]) => {
      return val.isEnabled
    })
    .map(([key, val]) => {
      const { complexity, discount, cost } = val
      const price = (cost * (100 - discount)) / 100
      return {
        title: parametersNames[key],
        complexity: complexity || '—',
        cost,
        discount,
        total: price
      }
    })

  return {
    commonDataTable,
    accuracyTable,
    priceTable,
    sectors,
    result,
    header,
    user,
    domenName: domen
  }
}

export const setUserSettings = userSettings => {
  const formData = new FormData()
  formData.append('settings', JSON.stringify(userSettings))
  return api.post('/user_settings', formData)
}
