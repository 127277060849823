import en from './locales/en'
import ru from './locales/ru'
import { i18n } from '@/plugins/i18n'
import { findJunctionOriginalName } from '@/utils'

export default {
  en,
  ru
}

const translateUnits = units => i18n.t(`message.units.${units}`)

export const normalizeResponseUnits = (response, allJunctions, allSectors) => {
  response.total.materials.map(material => {
    return material.map(item => {
      return (item.units = translateUnits(item.units))
    })
  })
  response.total.computation.map(item => {
    return (item.units = translateUnits(item.units))
  })
  response.sectors.map((sector, sectorIndex) => {
    sector.sector.system.map(system => {
      return system.materials.map(material => {
        return (material.units = translateUnits(material.units))
      })
    })
    if (sector.sector.junctions) {
      sector.sector.junctions = sector.sector.junctions.map(junction => {
        const name = findJunctionOriginalName(
          junction.name,
          allSectors[sectorIndex].junctions,
          allJunctions
        )
        const prependedStr = i18n.t('message.details.system.usedMaterials.table.junctionType')
        return {
          // ...junction,
          name: `${prependedStr} ${name}`,
          materials: junction.materials.map(material => {
            return {
              ...material,
              units: translateUnits(material.units)
            }
          })
        }
      })
    }
    // eslint-disable-next-line no-unused-expressions
    sector.junctions?.map(junction => {
      return junction.map(layer => {
        return layer.materials.map(material => {
          return (material.units = translateUnits(material.units))
        })
      })
    })
    // eslint-disable-next-line no-unused-expressions
    sector.junctionsMaterials?.map(junction => {
      return junction.map(layer => {
        return (layer.units = translateUnits(layer.units))
      })
    })
    return sector
  })
  return response
}
