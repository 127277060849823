import logoSrc from '@/assets/img/logo-ru.svg'
import { gradesValues } from '@/utils/rating'
export default {
  message: {
    notSupport: {
      title:
        'Извините, для работы с калькулятором вам необходимо использовать более современный браузер.',
      text:
        'Ваш текущий браузер не поддерживает все необходимые функции. Если у вас на компьютере нет установленной последней версии подходящего браузера, например Google Chrome, Mozilla Firefox или Opera',
      subtext: 'вы можете скачать их с официальных сайтов:'
    },
    meta: {
      title: 'ТЕХНОНИКОЛЬ | Калькулятор материалов плоской кровли'
    },
    computed: {
      show: 'Показать',
      hide: 'Скрыть'
    },
    home: {
      logo: logoSrc,
      title: 'Калькулятор материалов плоской кровли',
      btn: 'Начать расчет',
      majorText:
        'C помощью данного калькулятора вы сможете рассчитать необходимое количество материалов, для устройства плоской кровли',
      question: 'Есть вопросы?',
      phone: '8 800 200 0565',
      minorText:
        'Онлайн калькулятор создан специалистами корпорации ТехноНИКОЛЬ. Все данные, используемые в расчетах конкретных систем (кроме данных, которые нужно ввести вручную), выбираются согласно ГОСТ, СНиП и ISO для каждой системы в отдельности.'
    },
    footer: {
      links: [
        {
          url: 'https://shop.tn.ru/teploizoljacija',
          title: 'Где купить?'
        },
        {
          url: 'https://nav.tn.ru/services/proektno-raschetnyy-tsentr/form/',
          title: 'Заказать профессиональный расчет в ПРЦ'
        },
        {
          url: 'https://nav.tn.ru/services/support/',
          title: 'Задать вопрос эксперту'
        }
      ],
      error: 'Нашли {break}ошибку?',
      coder: 'Код {break}на сайт'
    },
    header: {
      title: 'Калькулятор плоской кровли',
      error: 'Нашли {break}ошибку?',
      coder: 'Код {break}на сайт'
    },
    missingPage: {
      textTop:
        'К сожалению по данной ссылке сохраненных объектов не найдено, возможно Вы попали сюда случайно?',
      textBottom: 'Вы можете начать расчет заново.',
      btn: 'Начать расчет'
    },
    buttons: {
      newSystem: 'Новая система',
      customize: {
        system: 'Настроить систему',
        junction: 'Настроить примыкание'
      },
      editSystem: 'Редактировать систему',
      newJunction: 'Новое примыкание',
      restoreDefault: 'Вернутся к исходному {break}варианту системы',
      addJunction: 'Добавить узел {break}примыкания',
      newSector: 'Добавить {break}новый участок'
    },
    nav: {
      steps: {
        stepSystems: 'Выбор системы {break}кровли',
        stepSystem: 'Настройка {break}системы кровли',
        stepWedge: 'Настройка {break}уклонов',
        stepJunctions: 'Добавление {break}примыкания',
        stepJunction: 'Настройка {break}примыкания'
      },
      calculation: 'Расчет'
    },
    systems: {
      title: 'Выберите систему плоской кровли',
      create: 'Создать {break}пользовательскую {break}систему',
      filterClear: 'Сбросить фильтры',
      more: 'подробнее на сайте'
    },
    system: {
      breadcrumb: 'Список систем',
      info: 'Задайте параметры слоев системы',
      pane: {
        square: 'Площадь',
        units: 'м²',
        typeTn: 'Система {break}ТехноНИКОЛЬ',
        typeCustom: 'Пользовательская {break}система'
      },
      layers: {
        fasteners: {
          attach: 'Крепеж крепит клины',
          attachTooltip: 'Крепеж так крепит плиты что аж плиты крепятся крепежем'
        }
      },
      search: {
        hint: 'Введите более 3х символов из названия материала',
        parentLayerError: 'К сожалению, для этого материала нет функциональных слоев.',
        selectLayer: 'Выберите слой',
        selectParentLayer: 'Выберите функциональный слой',
        inputPlaceholder: 'Поиск материала',
        notFound: 'По запросу: {query} ничего не найдено.'
      }
    },
    junctions: {
      title: 'Выберите тип примыкания',
      create: 'Создать {break}пользовательский {break}узел примыкания',
      togglerText: '{word} все примыкания на участке',
      calculate: 'Перейти {break}в расчет',
      group: {
        title: 'Выберите типоразмер',
        button: 'Перейти к редактированию примыкания'
      }
    },
    junction: {
      breadcrumb: 'Список примыканий',
      info: 'Добавьте необходимые функциональные слои и задайте параметры слоев',
      pane: {
        junctionType: 'Тип примыкания',
        count: 'К-во аналогичных примыканий',
        length: 'Длина узла примыкания',
        unitsPieces: 'шт',
        typeTn: 'Узел примыкания {break}ТехноНИКОЛЬ',
        typeCustom: 'Пользовательский {break}узел примыкания',
        groupType: 'Типоразмер',
        types: {
          linear: 'линейное',
          chiseled: 'точечное'
        }
      },
      sidebar: {
        junctionAdd: 'Параметры текущего узла сохранятся и добавятся в общий расчет',
        sectorAdd: 'Параметры текущего участка сохранятся и добавятся в общий расчет'
      },
      layers: {
        mount: {
          fastener: 'Крепежный элемент',
          base: 'Материал {break}основания',
          height: 'Высота {break}крепления',
          rate: 'Расход {break}крепежа'
        },
        footer: {
          mark: 'Марка элемента',
          attachLinear: 'Расход на 1 м.п. примыкания',
          attachChiseled: 'Расход на 1 примыкание',
          attachTooltip: 'Крепеж так крепит плиты что аж плиты крепятся крепежем'
        }
      }
    },
    wedge: {
      title: 'Устройство клиновидного слоя',
      info: 'Добавьте необходимые функциональные слои и задайте параметры слоев',
      layers: [
        {
          name: 'Основной уклон',
          id: 'mainSlope'
        },
        {
          name: 'Контруклон',
          id: 'contrSlope',
          square: 'площадь контруклона'
        }
      ],
      insulation: 'Клиновидная изоляция',
      add: 'Добавить позицию',
      fasteners: 'Крепежный элемент',
      mainSlope: 'Основной уклон',
      contrSlope: 'Контруклон',
      glue: {
        title: 'Клеевой слой',
        tooltip: 'площадь нанесения клея',
        tooltipText: 'используется для расчета расхода клея'
      },
      pane: {
        importLink: 'Импорт из расчета {break}калькулятора клинов',
        importXls: 'Импорт из Excel',
        useService: 'Использовать сервис {break}для расчета клинов'
      },
      importModal: {
        title: 'Импорт из расчета калькулятора клинов',
        text:
          'Эта функция позволяет импортировать данные расчета плит и крепежа из готового расчета {link}. {break}{break}Используйте ссылку, которую вы получили при сохранении проекта расчета клинов.',
        link: 'Калькулятора клинов',
        url: 'https://nav.tn.ru/calculators/wedge-insulating/',
        btn: 'импорт',
        continue: 'продолжить',
        errorLink:
          'Ссылка на проект либо содержит ошибку либо устарела. Убедитесь, что проект сохранен в системе и открывается по ссылке.',
        errorProject:
          'Проект по ссылке не соответствует требованиям. Скорее всего для для участков применено больше чем 2 вида клиновидной изоляции. {break}{break}Мы рекомендуем использовать только 1 материал для основного уклона и только 1 для контр уклона.'
      },
      importTable: {
        title: 'Импорт из Excel',
        placeholder: 'вставьте скопированную таблицу из excel в это поле',
        btn: 'импорт',
        continue: 'продолжить',
        errors: {
          format: 'Неверный формат данных для импорта из Excel.',
          download: 'Скачайте файл шаблона.',
          notFound:
            'Код ЕКН материала клиновидной изоляции не найден. Проверьте правильность заполнения ЕКН.',
          title: 'таблица не валидна:',
          list: [
            'таблица должна иметь только 2 столбца',
            'все значения должны быть в числовом формате'
          ]
        }
      }
    },
    details: {
      breadcrumb: {
        total: 'Общий расчет',
        computation: 'Расчет'
      },
      system: {
        edit: 'Редактировать систему',
        structureTitle: 'Состав кровли',
        junctionsNavTitle: 'Примыкания на участке',
        head: {
          title: 'Параметры участка',
          system: 'Система кровли',
          square: 'Площадь участка',
          base: 'Основание кровли'
        },
        usedMaterials: {
          title: 'Подробный расчет материалов на участке',
          titleTotal: 'Общий расчет материалов на участке',
          table: {
            materials: 'Материалы для системы кровли',
            junctionMaterials: 'Материалы для примыканий',
            junctionType: 'Тип примыкания:',
            count: 'К-во',
            computation: 'по расчету',
            package: 'с упаковкой'
          }
        }
      },
      junction: {
        edit: 'Редактировать узел примыкания',
        structureTitle: 'Состав слоев примыкания',
        backToResult: 'Вернуться к {break}полному расчету',
        fastener: 'Крепежный элемент. Алгоритм',
        other: 'Другие примыкания на участке',
        head: {
          title: 'Параметры примыкания',
          junction: 'Примыкание',
          type: {
            title: 'Тип примыкания',
            linear: 'Линейное',
            chiseled: 'Точечное'
          },
          count: 'К-во',
          length: 'Длина'
        },
        usedMaterials: {
          title: 'Подробный расчет материалов примыкания',
          titleTotal: 'Общий расчет материалов примыкания',
          table: {
            materials: 'Материалы'
          }
        }
      }
    },
    productsModal: {
      allMaterials: 'Все материалы',
      title: 'Выберите материал',
      chose: 'Выбрать',
      more: 'подробнее на сайте'
    },
    uploader: {
      add: 'Добавить чертёж'
    },
    units: {
      mm: 'мм',
      m: 'м',
      m2: 'м²',
      pc: 'шт',
      mp: 'м.п.',
      kg: 'кг',
      l: 'л',
      m3: 'м³'
    },
    preFooter: {
      pdf: 'Скачать расчет PDF',
      xls: 'Скачать расчет XLS',
      print: 'Распечатать расчет',
      save: 'Сохранить расчет',
      saveText: 'Все параметры и расчетные данные будут сохранены и доступны по ссылке'
    },
    result: {
      head: {
        title: 'Результаты расчета',
        square: 'площадь всех участков',
        words: {
          sectors: ['участок', 'участка', 'участков'],
          junctions: ['примыкание', 'примыкания', 'примыканий']
        }
      },
      nav: {
        title: 'Детальный расчет по участкам',
        info: 'Нажмите на участок для просмотра его расчета'
      },
      usedMaterials: {
        nav: {
          title: 'Применяется на участках'
        },
        table: {
          title: 'Общий расчет материалов',
          titleBySectors: 'Расчет материалов по участкам',
          material: 'Материал',
          count: 'К-во',
          computation: 'по расчету',
          package: 'с упаковкой',
          packageMob: 'с упаков.',
          hide: 'Скрыть все:',
          show: 'Показать все:',
          sectors: 'Участки',
          sectorsMob: 'Уч.'
        }
      }
    },
    addons: {
      title: 'Альбомы и инструкции по материалам и монтажу'
    },
    fastenerInfo: {
      title: 'Крепежный элемент',
      titleAdd: 'Добавить крепежный элемент',
      base: 'Материал {break}основания',
      height: 'Высота {break}крепления',
      rate: 'Расход {break}крепежа'
    },
    add: {
      item: 'Добавить позицию',
      layer: 'Добавить функциональный слой'
    },
    save: {
      title: 'Сохранить объект в системе',
      success: 'Проект успешно сохранен в системе',
      successInfo:
        'На вашу почту была отправлена ссылка на текущую версию проекта. Обратите внимание, если вы в дальнейшем внесете изменения в проект, то при сохранении проекта вам будет предоставлена новая ссылка.',
      info:
        'После сохранения объекта в системе, вы получите внутреннюю ссылку на проект. По ссылке в любой удобный момент вы сможете открыть проект и при необходимости внести в него корректировку. Ссылка на текущую версию проекта будет вам отправлена на указанную электронную почту.',
      form: {
        objectName: 'Название объекта',
        objectAddress: 'Адрес объекта',
        userName: 'ФИО',
        userPhone: 'Телефон',
        company: 'Компания',
        suggestions: 'Выберите вариант или продолжите ввод',
        errors: {
          required: 'Поле обязательно',
          length: 'Поле должно быть длинне',
          lengthCase1: '-ти',
          lengthCase2: '-х',
          lengthCase3: '-ми',
          invalidMail: 'E-mail не валиден',
          symbols: 'символов'
        }
      },
      buttons: {
        save: 'Сохранить расчет',
        back: 'Вернуться к полному расчету',
        copy: 'копировать',
        link: 'ссылку',
        new: 'Начать новый расчет'
      }
    },
    stepButtons: {
      choseSystem: 'Выбор системы',
      choseJunction: 'Выбор узла примыкания',
      addJunction: 'Добавление примыкания',
      replaceJunction: 'Замена примыкания',
      setUpSystem: 'Настройка системы',
      setUpWedge: 'Настройка уклонов',
      result: 'Расчёт'
    },
    headPaneMenu: {
      delete: 'Удалить',
      system: {
        remake: 'Преобразовать в пользовательскую систему',
        restore: 'Вернутся к исходному варианту системы'
      },
      junction: {
        remake: 'Преобразовать в пользовательский узел примыкания',
        restore: 'Вернутся к исходному варианту примыкания',
        removeDrawing: {
          title: 'Удалить текущий чертеж узла?',
          confirm: 'Да',
          cancel: 'Нет'
        }
      }
    },
    deleteSectorConfirmation: {
      text:
        'Данный участок — последний в текущем расчете. {break}Подтвердив удаление, новый расчет будет начат автоматически',
      confirm: 'Подтвердить удаление',
      cancel: 'Отклонить'
    },
    calculatorRating: {
      title: 'Оцените работу калькулятора',
      successMessage: {
        idea: 'Ваше предложение успешно отправлено, спасибо!',
        rating: 'Ваш комментарий успешно отправлен, спасибо!'
      },
      credentials: {
        accept: 'Нажимая на кнопку я подтверждаю согласие с',
        privacy: 'политикой конфиденциальности'
      },
      submit: 'Отправить отзыв',
      idea: 'Предложить идею',
      errors: {
        required: 'Поле обязательно',
        email: 'E-mail не валиден'
      },
      fields: {
        name: {
          placeholder: 'Укажите ваше имя',
          label: 'Имя'
        },
        email: {
          placeholder: 'Укажите вашу эл. почту',
          label: 'Электронная почта'
        },
        message: {
          placeholder: 'Пожалуйста, оставьте ваши пожелания для улучшения работы сервиса ',
          label: 'Комментарий'
        }
      },
      grades: {
        [gradesValues.bad]: 'Плохо',
        [gradesValues.neutral]: 'Нормально',
        [gradesValues.good]: 'Отлично',
        [gradesValues.idea]: 'Предложить идею'
      }
    }
  }
}
