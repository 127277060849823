import { api } from '@/api/index'
export const sendMetrics = (sessionToken, stepNumber, userInfo, sectors, result) => {
  let postData = new FormData()
  postData.append('date', new Date().toISOString())
  postData.append('sessionToken', sessionToken)
  postData.append('stepNumber', stepNumber)
  postData.append('data', JSON.stringify(sectors))
  postData.append('userInfo', JSON.stringify(userInfo))
  postData.append('result', JSON.stringify(result))
  postData.append('location', 'ru')
  return api.post('/metrics', postData)
}
