<template>
  <span :class="wrapperClasses">
    <input
      @input="handle"
      :type="type"
      :id="`input--${field.name}`"
      :placeholder="placeholder"
      class="input"
    />
    <span class="form-item__errors" v-if="isError">
      <slot name="errors" />
    </span>
  </span>
</template>

<script>
const placeholders = {
  'login-email': 'E-mail',
  'login-password': 'Пароль'
}
export default {
  name: 'AppInput',
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    isError: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  methods: {
    handle(e) {
      this.$emit('input', e.target.value)
    }
  },
  computed: {
    wrapperClasses() {
      return {
        'form-item': true,
        'form-item--error': this.isError
      }
    },
    placeholder() {
      return placeholders[this.field.name]
    }
  }
}
</script>

<style scoped lang="sass">
.form-item
  position: relative
  width: 100%
  // &:after
    content: ""
    position: absolute
    left: 0
    bottom: 0
    height: rem(1)
    background: $red_A
    transition: .5s
    width: 0
    pointer-events: none

  .input
    width: 100%
    box-sizing: border-box
    border: rem(1) solid $cool_A
    border-radius: rem(4)
    padding: rem(10) rem(14)
    text-overflow: ellipsis
    font-family: $main-font
    font-style: normal
    font-weight: 600
    font-size: rem(16)
    line-height: 100%
    color: $black_A
    outline: none
    transition: .5s
    &::-webkit-input-placeholder
      color: $dark-grey
    &:-moz-placeholder
      color: $dark-grey
    &::-moz-placeholder
      color: $dark-grey
    &:-ms-input-placeholder
      color: $dark-grey
    &:focus
      box-shadow: 0 0 rem(5) rgba(51, 51, 51, 0.2), 0 rem(5) rem(15) rgba(51, 51, 51, 0.1)
      border-color: $white
  &--error
    .input
      border-bottom-color: $red_A
    .error
      position: relative
</style>
