import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import messages from '../translations';
// import en from '@/translations/locales/en';
import ru from '@/translations/locales/ru'

const messages = { ru }

Vue.use(VueI18n)

export const supportedLanguages = ['ru'] // 'en'

export const i18n = new VueI18n({
  locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru',
  fallbackLocale: 'ru',
  // messages,
  messages
})
