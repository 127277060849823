import { convertISOtoDate } from '@/utils/DateConverters'

export const steps = {
  commonData: 'commonData',
  calculationAccuracy: 'calculationAccuracy',
  calculationPrice: 'calculationPrice'
}

export const stepsNames = {
  [steps.commonData]: 'Общие данные расчета',
  [steps.calculationAccuracy]: 'Точность расчета',
  [steps.calculationPrice]: 'Стоимость расчета'
}

export const selectFieldKeys = {
  customer: 'customer',
  objectRegion: 'objectRegion',
  calculationType: 'calculationType',
  clientRegion: 'clientRegion',
  stage: 'stage',
  clientType: 'clientType'
}

export const selectFieldOptions = {
  [selectFieldKeys.customer]: 'customerTypes',
  [selectFieldKeys.objectRegion]: 'regions',
  [selectFieldKeys.clientRegion]: 'regions',
  [selectFieldKeys.calculationType]: 'calculationTypes',
  [selectFieldKeys.stage]: 'buildingStages',
  [selectFieldKeys.clientType]: 'clientTypes'
}

export const applicationDataKeys = {
  startDate: 'startDate',
  endDate: 'endDate',
  applicationNumber: 'applicationNumber',
  promoCode: 'promoCode',
  variantsCount: 'variantsCount',
  variantNumber: 'variantNumber',
  calculationType: 'calculationType',
  calculationNumber: 'calculationNumber',
  cipher: 'cipher',
  cloneIndex: 'cloneIndex'
}

export const objectDataKeys = {
  name: 'name',
  address: 'address',
  CRMNumber: 'CRMNumber',
  stage: 'stage',
  objectRegion: 'objectRegion',
  square: 'square',
  sectorsCount: 'sectorsCount'
}

export const customerOrClientsDataKeys = {
  customer: 'customer',
  position: 'position',
  name: 'name',
  email: 'email',
  phone: 'phone',
  city: 'city',
  INN: 'INN',
  KPP: 'KPP',
  clientType: 'clientType',
  organization: 'organization',
  clientRegion: 'clientRegion',
  other: 'other'
}

export const generateCipher = (
  userId,
  calculationNumber,
  endDate,
  calculationPrice,
  calculationType,
  variantNumber,
  variantsCount,
  cloneIndex,
  calculationTypes,
  updateCipher
) => {
  const dot = '.'
  const [, month, year] = convertISOtoDate(endDate).split(dot)
  // const MPK = 'МПК'
  const dynamicPart = generateDynamicPart(calculationPrice)
  const dash = '-'
  let checkType
  if (updateCipher) {
    checkType = true
  } else {
    checkType =
      calculationTypes && calculationTypes.find(ct => ct.id === calculationType)
        ? calculationTypes.find(ct => ct.id === calculationType).name !== 'Новый расчёт'
        : false
  }

  const finalCalculationNumber = cloneIndex > 1 && checkType ? `(${cloneIndex})` : ''
  const finalVariantNumber = variantsCount > 1 ? dot + variantNumber : ''
  const [firstPartOfApplicationNumber] = calculationNumber.toString().split(dash)
  return `${userId}${dash}${firstPartOfApplicationNumber}${finalVariantNumber}${finalCalculationNumber}${dash}${dynamicPart}${dash}${month}${dot}${year.substr(
    -2
  )}`
}

const generateDynamicPart = calculationPrice => {
  return Object.values(calculationPrice).reduce((acc, val) => {
    return val.isEnabled ? `${acc}${val.shortcut}` : `${acc}`
  }, '')
}

export const checkIsFormValid = (allFields, requiredFields) => {
  const onlyRequiredFields = Object.entries(allFields).filter(item => {
    const [key] = item
    return requiredFields.includes(key)
  })
  return onlyRequiredFields.every(item => {
    const [, val] = item
    return !!val
  })
}

export const handleError = (notifyInstance, error) => {
  notifyInstance({
    group: 'apiNotifications',
    type: 'error',
    title: 'Возникла ошибка',
    text: error || ''
  })
}
