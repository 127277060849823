<template>
  <transition-group name="modal">
    <div class="modal-backdrop" v-if="isModalOpen" @click.self="close" key="backdrop"></div>
    <div class="modal-overlay" v-if="isModalOpen" key="overlay">
      <div class="modal-wrapper" :class="classes" :style="styles">
        <div class="modal-body">
          <button
            @click="close"
            v-if="isShowClose"
            class="close"
            :class="{ 'close--in-header': isCloseInHeader }"
          >
            <img src="@/assets/img/icons/uploader--modal-close.svg" alt="❌" />
          </button>
          <slot name="body" />
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    isNoRadius: Boolean,
    isCloseInHeader: Boolean,
    isBaseLayerSelected: {
      type: Boolean
    },
    isShowClose: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: true
    },
    bg: {
      type: String,
      default: 'white',
      validator: v => ['white', 'grey'].includes(v)
    },
    radius: {
      type: Number,
      default: 4
    },
    isNoBg: Boolean
  },
  methods: {
    close() {
      this.canClose ? this.$emit('close') : this.$emit('close', { isDeleteNewLayer: true })
      // if (this.canClose) {
      //   this.$emit('close');
      // }
    }
  },
  computed: {
    classes() {
      return {
        'modal-wrapper--no-radius': this.isNoRadius,
        [`modal-wrapper--${this.bg}`]: this.bg
      }
    },
    styles() {
      return {
        'border-radius': this.isNoRadius ? '0px' : `${this.radius}px`
      }
    }
  },
  watch: {
    isModalOpen() {
      this.isModalOpen ? this.$root.offBodyScroll() : this.$root.onBodyScroll()
    }
  }
}
</script>

<style scoped lang="sass">
.modal
  &-overlay, &-backdrop
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 4
  &-overlay
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    pointer-events: none
  &-backdrop
    background: rgba(51, 51, 51, 0.41)
    position: fixed
    top: 0
    left: 0
  &-wrapper

    box-shadow: $default-box-shadow
    border-radius: rem(4)
    pointer-events: all
    &--no-radius
      border-radius: 0
    &--white
      background: $white
    &--grey
      background: $ec
  &-body
    position: relative
    .close
      @extend .clear-btn
      height: rem(40)
      width: rem(40)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      position: absolute
      right: 0
      top: 0
      z-index: 4
      &--in-header
        +media((height: (320: rem(48), 960: rem(64))))
        +media((right: (320: 0, 960: calc((100% - 960px) / 2))))
        z-index: 3
.modal-enter-active, .modal-leave-active
  transition: .5s
.modal-enter, .modal-leave-to
  -webkit-transform: scale(1.1)
  opacity: 0
  transform: scale(1.1)
</style>
