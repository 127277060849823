<template>
  <div class="controls-dropdown">
    <app-button @click="goToDashboard">Кабинет</app-button>
    <app-button @click="logOut">Выйти</app-button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import AppButton from '@/components/elements/AppButton'
import { logoutMethod } from '@/api/moderator'
import { moderatorLocalStorageHelper } from '@/utils/moderator'

export default {
  name: 'UserControlsDropDown',
  components: { AppButton },
  methods: {
    ...mapMutations('moderator', {
      UPDATE_AUTH_STATUS: 'UPDATE_AUTH_STATUS',
      RESTORE_DEFAULT_DEEP_SETTINGS: 'RESTORE_DEFAULT_DEEP_SETTINGS'
    }),
    logOut() {
      const { csrfToken, logoutToken } = moderatorLocalStorageHelper.getTokens()
      logoutMethod({ csrfToken, logoutToken }).then(() => {
        this.UPDATE_AUTH_STATUS({
          status: false,
          uid: null
        })
        moderatorLocalStorageHelper.clearTokens()
        this.RESTORE_DEFAULT_DEEP_SETTINGS()
      })
      if (this.isRedirectNeed) {
        this.$router.push({ name: 'Home', params: { lang: this.$i18n.locale } })
      }
    },
    goToDashboard() {
      if (!this.isInDashboardNow) {
        this.$router.push(`/${this.$i18n.locale}/dashboard`)
      }
    }
  },
  computed: {
    protectedRoutes() {
      return this.$router.options.routes.reduce((acc, val) => {
        if (val.meta?.isAuthNeed) {
          acc = [...acc, val.name]
        }
        if (val.children) {
          val.children.forEach(childRoute => {
            if (childRoute.meta?.isAuthNeed) {
              acc = [...acc, childRoute.name]
            }
          })
        }
        return acc
      }, [])
    },
    isRedirectNeed() {
      const { $route, protectedRoutes } = this
      return protectedRoutes.includes($route.name)
    },
    isInDashboardNow() {
      return this.$route.name === 'Dashboard'
    }
  }
}
</script>

<style scoped lang="sass">
.controls-dropdown
  display: grid
</style>
