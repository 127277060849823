import Vue from 'vue'
import Router from 'vue-router'
import { i18n, supportedLanguages } from '@/plugins/i18n'
import store from '@/store'
import { checkIsLoggedIn } from '@/api/moderator'
Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: `${i18n.locale}`
    },
    {
      path: '/:lang',
      component: {
        render(h) {
          return h('router-view')
        }
      },
      children: [
        {
          path: '/',
          name: 'Home',
          component: () => import('@/views/Home.vue')
        },
        {
          path: 'wedge/:id',
          name: 'Wedge',
          component: () => import('@/views/Wedge.vue')
        },
        {
          path: 'systems/:id',
          name: 'Systems',
          component: () => import('@/views/Systems.vue')
        },
        // {
        //   path: '/system/:id',
        //   name: 'System',
        //   component: () => import('../views/Single-system.vue'),
        //   children: [
        //     {
        //       path: 'junction/:number',
        //       name: 'Junction',
        //       component: () => import('../views/Single-junction.vue'),
        //     },
        //   ]
        // },
        {
          path: 'system/:id',
          name: 'System',
          component: () => import('@/views/Single-system.vue')
        },
        {
          path: 'system/:id/junction/:number',
          name: 'Junction',
          component: () => import('@/views/Single-junction.vue')
        },
        {
          path: 'junctions/:id',
          name: 'Junctions',
          component: () => import('@/views/Junctions.vue')
        },
        {
          path: 'result',
          name: 'Result',
          component: () => import('@/views/Result.vue')
        },
        {
          path: 'details/:id',
          name: 'Details',
          component: () => import('@/views/Details.vue')
        },
        {
          path: 'details/:id/junction/:number',
          name: 'DetailsJunction',
          component: () => import('@/views/Details-junction.vue')
        },
        {
          path: 'save',
          name: 'Save',
          component: () => import('@/views/Save.vue')
        },
        {
          path: 'deep-settings/:hash',
          name: 'DeepSettings',
          component: () => import('@/views/DeepSettings.vue'),
          meta: {
            isAuthNeed: true
          }
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard.vue'),
          meta: {
            isAuthNeed: true
          }
        },
        {
          path: '404',
          name: '404',
          component: () => import('@/views/404.vue')
        },
        {
          path: '*',
          name: 'MissingPage',
          component: () => import('@/views/404.vue')
        }
      ]
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  const isLanguageSupport = supportedLanguages.includes(to.params.lang)
  const language = isLanguageSupport ? to.params.lang : i18n.locale
  if (!isLanguageSupport) {
    next({ path: `/${language}${to.fullPath}` })
  } else {
    if (!i18n.vm.messages[language]) {
      import(`@/translations/locales/${language}.js`).then(response => {
        i18n.vm.messages = Object.assign(i18n.vm.messages, { [language]: { ...response.default } })
        i18n.locale = language
      })
      // localStorage.setItem('lang', language);
    }
    // localStorage.setItem('lang', language);
    next()
  }
})
router.beforeEach(async (to, from, next) => {
  const { isAuthNeed } = to.meta
  const isLoggedIn = store.state.moderator.isAuth
  if (isAuthNeed) {
    if (isLoggedIn) {
      next()
    } else {
      try {
        const isLoggedInByRequest = await checkIsLoggedIn()
        if (isLoggedInByRequest.data) {
          next()
        } else {
          next('/')
        }
      } catch (e) {}
    }
  } else {
    next()
  }
})
export default router
