import logoSrc from '@/assets/img/logo-en.svg'
import { gradesValues } from '@/utils/rating'

export default {
  message: {
    notSupport: {
      title: 'Sorry, you need to use a more modern browser to work with the calculator.',
      text:
        'Your current browser does not support all the necessary functions. If you don’t have the latest version of a suitable browser installed on your computer, for exampleGoogle Chrome, Mozilla Firefox or Opera',
      subtext: 'you can download them from official sites:'
    },
    meta: {
      title: 'TECHNONICOL | Flat roof calculator'
    },
    computed: {
      show: 'Show',
      hide: 'Hide'
    },
    home: {
      logo: logoSrc,
      title: 'calculator ploskoy krovli',
      btn: 'Get started',
      majorText:
        'С помощью данного калькулятора вы сможете рассчитать необходимое количество  теплоизоляции для формирования основного и контруклона на плоской кровле. en',
      question: 'Have questions?',
      phone: '8 485 200 0565',
      minorText:
        'Онлайн калькулятор создан специалистами корпорации ТехноНИКОЛЬ. Все данные используемые в расчетах конкретных систем (кроме данных, которые нужно ввести вручную) выбираются согласно  ГОСТ, СНиП и ISO для каждой системы в отдельности. en'
    },
    footer: {
      links: [
        {
          url: 'https://shop.tn.ru/teploizoljacija',
          title: 'Where can I buy?'
        },
        {
          url: 'https://nav.tn.ru/services/proektno-raschetnyy-tsentr/form/',
          title: 'Order a professional calculation at the PRC'
        },
        {
          url: 'https://nav.tn.ru/services/support/',
          title: 'Ask an expert a question'
        }
      ],
      error: 'Found {break}a bug?',
      coder: 'Site {break}code'
    },
    header: {
      title: 'calculator ploskoy krovli',
      error: 'Found {break}a bug?',
      coder: 'Site {break}code'
    },
    missingPage: {
      textTop:
        'Unfortunately, no saved objects were found on this link, maybe you got here by accident?',
      textBottom: 'You can start the calculation again.',
      btn: 'Get started'
    },
    buttons: {
      newSystem: 'New system',
      customize: {
        system: 'Customize system',
        junction: 'Customize junction'
      },
      editSystem: 'Edit system',
      newJunction: 'New junction',
      restoreDefault: 'Restore {break}default',
      addJunction: 'Add {break}junction',
      newSector: 'Add {break}new sector'
    },
    nav: {
      steps: {
        stepSystems: 'Choosing {break}a roof system',
        stepSystem: 'Setting up {break}the roof system',
        stepWedge: 'Setting {break}the wedges',
        stepJunctions: 'Adjacency {break}selection',
        stepJunction: 'Configuring {break}adjacency'
      },
      calculation: 'Calculation'
    },
    systems: {
      title: 'Выберите систему плоской кровли en',
      create: 'Создать {break}пользовательскую {break}систему en',
      filterClear: 'Clear filters',
      more: 'подробнее на сайте en'
    },
    system: {
      breadcrumb: 'Systems list',
      info: 'Задайте параметры слоев системы en',
      pane: {
        square: 'Square',
        units: 'm²',
        typeTn: 'Система {break}Технониколь en',
        typeCustom: 'Пользовательская {break}система en'
      },
      layers: {
        fasteners: {
          attach: 'Крепеж крепит клины en',
          attachTooltip: 'Крепеж так крепит плиты что аж плиты крепятся крепежем en'
        }
      },
      search: {
        hint: 'Введите более 3х символов из названия материала en',
        parentLayerError: 'К сожалению, для этого материала нет функциональных слоев. en',
        selectLayer: 'Выберите слой en',
        selectParentLayer: 'Выберите функциональный слой en',
        inputPlaceholder: 'Поиск материала en',
        notFound: 'По запросу: {query} ничего не найдено. en'
      }
    },
    junctions: {
      title: 'Выберите тип примыкания en',
      create: 'Создать {break}пользовательский {break}узел примыкания en',
      togglerText: '{word} all junctions on sector',
      calculate: 'Перейти {break}в расчет en',
      group: {
        title: 'Выберите типоразмер en',
        button: 'Перейти к редактированию примыкания en'
      }
    },
    junction: {
      breadcrumb: 'Junctions list',
      info: 'Добавьте необходимые функциональные слои и задайте параметры слоев en',
      pane: {
        junctionType: 'Junction type',
        count: 'Number of similar',
        length: 'Длина узла примыкания en',
        unitsPieces: 'pc',
        typeTn: 'Узел примыкания {break}Технониколь en',
        typeCustom: 'Пользовательский {break}узел примыкания en',
        groupType: 'Типоразмер en',
        types: {
          linear: 'linear',
          chiseled: 'chiseled'
        }
      },
      sidebar: {
        junctionAdd: 'Параметры текущего узла сохранятся и добавятся в общий расчет en',
        sectorAdd: 'Параметры текущего участка сохранятся и добавятся в общий расчет en'
      },
      layers: {
        mount: {
          fastener: 'Крепежный элемент en',
          base: 'Материал {break}основания en',
          height: 'Высота {break}крепления en',
          rate: 'Расход {break}крепежа en'
        },
        footer: {
          mark: 'Element mark',
          attachLinear: 'Расход на 1 м.п. примыкания en',
          attachChiseled: 'Расход на 1 примыкание en',
          attachTooltip: 'Крепеж так крепит плиты что аж плиты крепятся крепежем en'
        }
      }
    },
    wedge: {
      title: 'Устройство клиновидного слоя en',
      insulation: 'Клиновидная изоляция en',
      info: 'Добавьте необходимые функциональные слои и задайте параметры слоев en',
      layers: [
        {
          name: 'Basic slope',
          id: 'mainSlope'
        },
        {
          name: 'Cricket slope',
          id: 'contrSlope',
          square: 'площадь контруклона en'
        }
      ],
      add: 'Добавить позицию en',
      fasteners: 'Крепежный элемент en',
      mainSlope: 'Basic slope',
      contrSlope: 'Cricket slope',
      glue: {
        title: 'Клеевой слой en',
        tooltip: 'площадь нанесения клея en',
        tooltipText: 'используется для расчета расхода клея en'
      },
      pane: {
        importLink: 'Импорт из расчета {break}калькулятора клинов en',
        importXls: 'Импорт из Excel en',
        useService: 'Использовать сервис {break}для расчета клинов en'
      },
      importModal: {
        title: 'Импорт из расчета калькулятора клинов en',
        text:
          'Эта функция позволяет импортировать данные расчета плит и крепежа из готового расчета {link}. {break}{break}Используйте ссылку, которую вы получили при сохранении проекта расчета клинов. en',
        link: 'Калькулятора клинов en',
        url: 'https://nav.tn.ru/calculators/wedge-insulating/',
        btn: 'import',
        continue: 'continue',
        errorLink:
          'Ссылка на проект либо содержит ошибку либо устарела. Убедитесь, что проект сохранен в системе и открывается по ссылке. en',
        errorProject:
          'Проект по ссылке не соответствует требованиям. Скорее всего для для участков применено больше чем 2 вида клиновидной изоляции. {break}{break}Мы рекомендуем использовать только 1 материал для основного уклона и только 1 для контр уклона. en'
      },
      importTable: {
        title: 'Импорт из Excel en',
        placeholder: 'вставьте скопированную таблицу из excel в это поле en',
        btn: 'import',
        continue: 'continue',
        errors: {
          format: 'Неверный формат данных для импорта из Excel. en',
          download: 'Скачайте файл шаблона. en',
          notFound:
            'Код ЕКН материала клиновидной изоляции не найден. Проверьте правильность заполнения ЕКН. en',
          title: 'таблица не валидна: en',
          list: [
            'таблица должна иметь только 2 столбца en',
            'все значения должны быть в числовом формате en'
          ]
        }
      }
    },
    details: {
      breadcrumb: {
        total: 'Общий расчет en',
        computation: 'Расчет en'
      },
      system: {
        edit: 'Редактировать систему en',
        structureTitle: 'Состав кровли en',
        junctionsNavTitle: 'Примыкания на участке en',
        head: {
          title: 'Параметры участка en',
          system: 'Система кровли en',
          square: 'Площадь участка en',
          base: 'Основание кровли en'
        },
        usedMaterials: {
          title: 'Подробный расчет материалов на участке en',
          titleTotal: 'Общий расчет материалов на участке en',
          table: {
            materials: 'Материалы для системы кровли en',
            junctionMaterials: 'Материалы для примыканий en',
            junctionType: 'Тип примыкания en:',
            count: 'C-t',
            computation: 'by comp',
            package: 'with pack'
          }
        }
      },
      junction: {
        edit: 'Редактировать узел примыкания en',
        structureTitle: 'Состав слоев примыкания en',
        backToResult: 'Вернуться к {break}полному расчету en',
        fastener: 'Крепежный элемент. Алгоритм en',
        other: 'Другие примыкания на участке en',
        head: {
          title: 'Параметры примыкания en',
          junction: 'Примыкание en',
          type: {
            title: 'Тип примыкания en',
            linear: 'linear',
            chiseled: 'chiseled'
          },
          count: 'C-t',
          length: 'Length'
        },
        usedMaterials: {
          title: 'Подробный расчет материалов примыкания en',
          titleTotal: 'Общий расчет материалов примыкания en',
          table: {
            materials: 'Материалы en'
          }
        }
      }
    },
    productsModal: {
      allMaterials: 'All materials',
      title: 'Выберите материал en',
      chose: 'Chose',
      more: 'more'
    },
    uploader: {
      add: 'Добавить чертёж en'
    },
    units: {
      mm: 'mm',
      m: 'm',
      m2: 'm²',
      pc: 'pc',
      mp: 'm.p.',
      kg: 'kg',
      l: 'l',
      m3: 'm³'
    },
    preFooter: {
      pdf: 'Скачать расчет PDF en',
      xls: 'Скачать расчет XLS en',
      print: 'Распечатать расчет en',
      save: 'Сохранить расчет en',
      saveText: 'Все параметры и расчетные данные будут сохранены и доступны по ссылке en'
    },
    result: {
      head: {
        title: 'Результаты расчета en',
        square: 'площадь всех участков en',
        words: {
          sectors: ['участок en', 'участка en', 'участков en'],
          junctions: ['примыкание en', 'примыкания en', 'примыканий en']
        }
      },
      nav: {
        title: 'Детальный расчет по участкам en',
        info: 'Нажмите на участок для просмотра его расчета en'
      },
      usedMaterials: {
        nav: {
          title: 'Применяется на участках en'
        },
        table: {
          title: 'Общий расчет материалов en',
          titleBySectors: 'Расчет материалов по участкам en',
          material: 'Material',
          count: 'C-t',
          computation: 'by comp',
          package: 'with pack',
          packageMob: 'w. pack',
          hide: 'Hide all:',
          show: 'Show all:',
          sectors: 'Sectors',
          sectorsMob: 'Se.'
        }
      }
    },
    addons: {
      title: 'Альбомы и инструкции по материалам и монтажу en'
    },
    fastenerInfo: {
      title: 'Крепежный элемент en',
      titleAdd: 'Добавить крепежный элемент en',
      base: 'Материал {break}основания en',
      height: 'Высота {break}крепления en',
      rate: 'Расход {break}крепежа en'
    },
    add: {
      item: 'Добавить позицию en',
      layer: 'Добавить функциональный слой en'
    },
    save: {
      title: 'Сохранить объект в системе en',
      success: 'Проект успешно сохранен в системе en',
      successInfo:
        'На вашу почту была отправлена ссылка на текущую версию проекта. Обратите внимание, если вы в дальнейшем внесете изменения в проект, то при сохранении проекта вам будет предоставлена новая ссылка. en',
      info:
        'После сохранения объекта в системе, вы получите внутреннюю ссылку на проект. По ссылке в любой удобный момент вы сможете открыть проект и при необходимости внести в него корректировку. Ссылка на текущую версию проекта будет вам отправлена на указанную электронную почту. en',
      form: {
        objectName: 'Object name',
        objectAddress: 'Object address',
        userName: 'Name',
        userPhone: 'Phone',
        company: 'Company',
        suggestions: 'Выберите вариант или продолжите ввод en',
        errors: {
          required: 'Required filed',
          length: 'Поле должно быть длинне en',
          lengthCase1: '-ти en',
          lengthCase2: '-х en',
          lengthCase3: '-ми en',
          invalidMail: 'Invalid E-mail',
          symbols: 'symbols'
        }
      },
      buttons: {
        save: 'Сохранить расчет en',
        back: 'Вернуться к полному расчету en',
        copy: 'copy',
        link: 'link',
        new: 'Начать новый расчет en'
      }
    },
    stepButtons: {
      choseSystem: 'Выбор системы en',
      choseJunction: 'Выбор узла примыкания en',
      addJunction: 'Добавление примыкания en',
      replaceJunction: 'Замена примыкания en',
      setUpSystem: 'Настройка системы en',
      setUpWedge: 'Настройка клинов en',
      result: 'Расчёт en'
    },
    headPaneMenu: {
      delete: 'Delete',
      system: {
        remake: 'Преобразовать в пользовательскую систему en',
        restore: 'Вернутся к исходному варианту системы en'
      },
      junction: {
        remake: 'Преобразовать в пользовательский узел примыкания en',
        restore: 'Вернутся к исходному варианту примыкания en',
        removeDrawing: {
          title: 'Удалить текущий чертеж узла? en',
          confirm: 'Yes',
          cancel: 'No'
        }
      }
    },
    deleteSectorConfirmation: {
      text:
        'Данный участок — последний в текущем расчете. {break}Подтвердив удаление, новый расчет будет начат автоматически en',
      confirm: 'Подтвердить удаление en',
      cancel: 'Отклонить en'
    },
    calculatorRating: {
      title: 'Оцените работу калькулятора en',
      successMessage: {
        idea: 'Ваше предложение успешно отправлено, thx!',
        rating: 'Ваш комментарий успешно отправлен, thx!'
      },
      credentials: {
        accept: 'Нажимая на кнопку я подтверждаю согласие с en',
        privacy: 'политикой конфиденциальности en'
      },
      submit: 'Отправить отзыв en',
      idea: 'Предложить идею en',
      errors: {
        required: 'Field is required',
        email: 'E-mail is not valid'
      },
      fields: {
        name: {
          placeholder: 'Укажите ваше имя en',
          label: 'Name'
        },
        email: {
          placeholder: 'Укажите вашу эл. почту en',
          label: 'Email'
        },
        message: {
          placeholder: 'Пожалуйста, оставьте ваши пожелания для улучшения работы сервиса en',
          label: 'Comment'
        }
      },
      grades: {
        [gradesValues.bad]: 'Bad',
        [gradesValues.neutral]: 'Neutral',
        [gradesValues.good]: 'Good',
        [gradesValues.idea]: 'Idea'
      }
    }
  }
}
