<template>
  <button :class="classes" @click="handler" :disabled="isDisabled">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#fff"
      :height="30"
      :width="30"
      :opacity="1"
      backgroundColor="transparent"
      :z-index="3"
      :is-full-page="false"
      v-if="isLoading"
    ></loading>
    <span class="app-button__icon" v-if="$slots.icon">
      <slot name="icon" />
    </span>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    transparent: {
      type: Boolean
    },
    isGrey: {
      type: Boolean
    },
    size: {
      type: String,
      default: null,
      validator: v => ['xs', 'small', 'large'].includes(v)
    },
    color: {
      type: String,
      default: null,
      validator: v => ['red', 'green', 'grey', 'black', 'white'].includes(v)
    },
    isAddButton: Boolean,
    isReverse: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean
  },
  methods: {
    handler() {
      if (!this.isDisabled) this.$emit('click')
    }
  },
  computed: {
    classes() {
      return {
        'app-button': true,
        'app-button--transparent': this.transparent,
        'app-button--grey': this.isGrey,
        'app-button--reverse': this.isReverse,
        'app-button--add': this.isAddButton,
        'app-button--only-icon': this.$slots.icon && !this.$slots.default,
        [`app-button--${this.size}`]: this.size,
        [`app-button--${this.color}`]: this.color
      }
    }
  }
}
</script>

<style scoped lang="sass">
.app-button
  @extend .clear-btn
  display: flex
  align-items: center
  background: $white
  transition: .5s
  padding: rem(8) rem(10)
  border-radius: rem(4)
  // min-width: rem(174)
  font-family: $main-font
  font-style: normal
  font-weight: 600
  font-size: rem(14)
  line-height: rem(16)
  color: $light-black
  text-align: left
  position: relative
  .vld-overlay
    line-height: 0
  &:disabled
    cursor: default
    color: $cool_A
    background: $default
    .app-button
      &__icon
        color: $cool_A
        // filter: invert(85%) sepia(14%) saturate(0%) hue-rotate(319deg) brightness(101%) contrast(92%)
  &:not(:disabled)
    @media (any-hover: hover)
      &:hover
        color: $black
        background: rgba($ec, .5)
        .app-button
          &__icon
            color: $red
            // filter: invert(19%) sepia(69%) saturate(7122%) hue-rotate(350deg) brightness(86%) contrast(110%)
  &__icon
    margin-right: rem(10)
    transition: .5s
    line-height: 0
    // filter: invert(100%) sepia(1%) saturate(4268%) hue-rotate(195deg) brightness(113%) contrast(68%)

  &--transparent
    background: transparent
    .app-button
      &__icon
        color: $black_C
  &--grey
    background: $ec
  &--red
    background: $red
    color: $white
  &--green
    color: $white
    background: $green
  &--black
    color: $white
    background: $black
    .app-button
      &__icon
        filter: invert(1)
    &:not(:disabled)
      @media(any-hover: hover)
        &:hover
          background: $black
          color: $white
          .app-button
            &__icon
              filter: invert(1)
  &--transparent, &--grey, &--red, &--green, &--white
    padding: rem(12) rem(14)
    .app-button
      &__icon
        margin: 0 rem(14) 0 0
  &--green
    .app-button
      &__icon
        filter: none
    &:not(:disabled)
      @media(any-hover: hover)
        &:hover
          background: $green-hover
          color: $white
          .app-button
            &__icon
              filter: none
  &--red
    .app-button
      &__icon
        filter: none
    &:not(:disabled)
      @media(any-hover: hover)
        &:hover
          background: $red-hover
          color: $white
          .app-button
            &__icon
              filter: none
  &--transparent, &--grey
    &:not(:disabled)
      @media(any-hover: hover)
        &:hover
          background: $black
          color: $white
          .app-button
            &__icon
              color: rgba($white, .75)
              // filter: invert(84%) sepia(0%) saturate(54%) hue-rotate(234deg) brightness(100%) contrast(98%)
  &--grey, &--white
    .app-button
      &__icon
        color: $black_C
        // filter: invert(60%) sepia(9%) saturate(9%) hue-rotate(336deg) brightness(102%) contrast(85%)
  &--only-icon
    padding: 0
    width: rem(40)
    justify-content: center
    display: flex
    .app-button
      &__icon
        margin: 0
  &--xs
    width: rem(30)
    height: rem(30)
    padding: 0
  &--small
    height: rem(32)
    width: rem(32)
  &--large
    height: rem(56)
    padding: rem(16) rem(20)
    .app-button
      &__icon
        margin-right: rem(20)
    &.app-button--reverse
      .app-button
        &__icon
          margin: 0 0 0 rem(20)
  &--reverse
    flex-direction: row-reverse
    .app-button
      &__icon
        margin: 0 0 0 rem(14)
  &--add
    width: 100%
    padding: rem(18) rem(20)
    border-radius: rem(4)
    border: rem(1) dashed $cool_A
    color: $black
    background: transparent
    .app-button
      &__icon
        filter: none
        margin-right: rem(20)
    &:not(:disabled)
      @media (any-hover: hover)
        &:hover
          background: transparent
          box-shadow: $default-box-shadow
          border-color: transparent
          .app-button
            &__icon
              filter: none
.table-row__cell--double
  .app-button
    justify-content: space-between
    width: 100%
</style>
